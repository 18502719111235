<template>
  <div v-if="isPaymentElementReadyForRender">
    <!-- Google Pay -->
    <AdyenExpressGooglePay
      v-if="availablePaymentMethods.includes('Google Pay')"
      :onReady="onReady"
      :adyenSdk="adyenSdk"
      :paymentsClient="paymentsClient"
      :currency="currency"
      :countryCode="countryCode"
    />

    <!-- PayPal -->
    <AdyenExpressPayPal
      v-if="availablePaymentMethods.includes('PayPal')"
      :onReady="onReady"
      :adyenSdk="adyenSdk"
      :paymentsClient="paymentsClient"
      :currency="currency"
      :onShippingAddressChanged="onShippingAddressChanged"
      :onShippingOptionsChange="onShippingOptionsChange"
    />
  </div>
</template>

<script lang="ts">
import { defineComponent, onMounted, ref, computed } from 'vue';

import { useMainStore } from '@/stores/MainStore';
import { useCartStore } from '@/stores/CartStore';
import { useAdvertiserStore } from '@/stores/AdvertiserStore';
import { usePaymentStore } from '@/stores/PaymentStore';
import { useShippingStore } from '@/stores/ShippingStore';

import AdyenExpressGooglePay from '@/components/Payment/Adyen/Express/GooglePay/AdyenExpressGooglePay.vue';
import AdyenExpressPayPal from '@/components/Payment/Adyen/Express/Paypal/AdyenExpressPayPal.vue';
import { AdyenPaymentError } from '@/types/errors.types';
import { Api as PaymentApi } from '@/api/payment/adyen';
import gtmTracker from '@/helpers/googleTagManager';

import type { PropType } from 'vue';
import type {
  AdyenShippingMethodType,
  AdyenPaymentMethodsType,
  AdyenAvailablePaymentMethod,
} from '@/types/payment.adyen.types';
import type { PaymentButtonTypeAdyen } from '@/stores/MainStore';
import type { AddressType, ShippingMethod } from '@/stores/ShippingStore';

export default defineComponent({
  name: 'AdyenFieldsExpressCheckout',
  components: {
    AdyenExpressGooglePay,
    AdyenExpressPayPal,
  },
  props: {
    // TODO use this TS PropType technique in other components!
    onReady: { type: Function as PropType<(p: PaymentButtonTypeAdyen) => void>, required: true },
    adyenSdk: { type: Object as PropType<ToDo>, required: true },
    currency: { type: String, required: true },
    countryCode: { type: String, required: true },
    locale: { type: String, required: true },
    paymentMethodsResponseData: {
      type: Object as PropType<{ paymentMethods: AdyenAvailablePaymentMethod[] }>,
      required: true,
    },
    availablePaymentMethods: {
      type: Array as PropType<AdyenPaymentMethodsType[]>,
      required: true,
    },
  },
  setup(props) {
    const mainStore = useMainStore();
    const cartStore = useCartStore();
    const advertiserStore = useAdvertiserStore();
    const paymentStore = usePaymentStore();
    const shippingStore = useShippingStore();

    const isPaymentElementReadyForRender = ref(false);
    const paymentsClient = ref();
    const shippingOptions = ref<AdyenShippingMethodType[]>();
    const selectedShippingOption = ref<AdyenShippingMethodType>();

    const totalPaymentPrice = computed(() => cartStore.getTotalPaymentPrice);

    const initPaymentClient = async () => {
      const amount = {
        value: props.amount * 100,
        currency: props.currency,
      };

      try {
        const configuration = {
          paymentMethodsResponse: props.paymentMethodsResponseData,
          clientKey: import.meta.env.VITE_ADYEN_CLIENT_KEY,
          environment: import.meta.env.VITE_APP_ENV === 'PROD' ? 'live' : 'test',
          amount: {
            value: totalPaymentPrice.value * 100,
            currency: props.currency,
          },
          locale: props.locale,
          countryCode: props.countryCode,

          onPaymentCompleted: (result: ToDo, component: ToDo) => {
            console.log('onPaymentCompleted', result, component);

            // Unmount the component
            component.core.remove(component);

            gtmTracker.doPlaceOrderGtmEvent({
              paymentType: component.props.name,
            });

            if (result?.resultCode !== 'Authorised') {
              return mainStore.goToErrorPage({
                error: new AdyenPaymentError(
                  `Unexpected result code ${result.resultCode} in onPaymentCompleted()!`,
                  null,
                  advertiserStore.paymentProvider!,
                ),
              });
            }
          },
          onPaymentFailed: (result: ToDo, component: ToDo) => {
            console.log('onPaymentFailed!!!', result, component);
            alert('payment failed yeh');
          },
          onError: (error: ToDo, component: ToDo) => {
            console.error('onError', error.name, error.message, error.stack, component);
          },
        };

        // console.log('Initialising payment element with configuration:', configuration);

        paymentsClient.value = await props.adyenSdk!.AdyenCheckout(configuration);

        // Just while I wait for other methods to be set up = DS 25/9/24
        // move to child component (Card in this case)
        // const card = new props.adyenSdk!.Card(paymentsClient).mount('#google-pay-container');

        isPaymentElementReadyForRender.value = true;
      } catch (err) {
        throw new AdyenPaymentError('Error in init()!', err, advertiserStore.paymentProvider!);
      }
    };

    // @see https://docs.adyen.com/payment-methods/paypal/web-component/express-checkout/?tab=1#handle-delivery-method-changes-advanced
    const onShippingAddressChanged = async ({
      component,
      address,
      shippingOptionsTransformFn,
      shopthruPspReference,
    }: {
      component: ToDo;
      address: AddressType;
      shippingOptionsTransformFn: (shippingOption: ShippingMethod) => AdyenShippingMethodType;
      shopthruPspReference: string;
    }) => {
      console.log('onShippingAddressChanged() called', {
        component,
        address,
        shippingOptionsTransformFn,
        shopthruPspReference,
      });

      try {
        const newShippingOptions: AdyenShippingMethodType[] = [];

        shippingStore.setAddress('billing', address);
        shippingStore.setAddress('shipping', address);

        await shippingStore.fetchShippingMethods();

        (shippingStore.shippingMethods || []).forEach((method, key) => {
          const newShippingOption = shippingOptionsTransformFn(method);

          if (selectedShippingOption.value) {
            newShippingOption.selected = selectedShippingOption.value.id === method.code;
          } else if (key === 0) {
            // If there is not already a shipping method set then lets set the first one as selected.
            newShippingOption.selected = true;
            selectedShippingOption.value = newShippingOption;
          }

          newShippingOptions.push(newShippingOption);
        });

        await cartStore.setShippingMethod(selectedShippingOption.value!.id);

        shippingOptions.value = newShippingOptions;

        const { paymentData: updatedPaymentData } = await PaymentApi.updatePayPalOrder({
          shopthruPspReference,
          paymentData: component.paymentData,
          shippingOptions: newShippingOptions,
          totalOrderPrice: totalPaymentPrice.value * 100,
        });

        component.updatePaymentData(updatedPaymentData);
      } catch (err) {
        mainStore.goToErrorPage({
          error: new AdyenPaymentError(
            'Error in onShippingAddressChanged()!',
            err,
            advertiserStore.paymentProvider!,
          ),
        });
      }
    };

    const onShippingOptionsChange = async ({
      component,
      id,
      shopthruPspReference,
    }: {
      component: ToDo;
      id: string;
      shopthruPspReference: string;
    }) => {
      console.log('onShippingOptionsChange() called with:', {
        component,
        id,
        shopthruPspReference,
      });

      try {
        await cartStore.setShippingMethod(id);

        const updatedShippingOptions = shippingOptions.value?.map((shippingOption) => ({
          ...shippingOption,
          selected: shippingOption.id === id,
        }));

        const { paymentData: updatedPaymentData } = await PaymentApi.updatePayPalOrder({
          shopthruPspReference,
          paymentData: component.paymentData,
          shippingOptions: updatedShippingOptions,
          totalOrderPrice: totalPaymentPrice.value * 100,
        });

        component.updatePaymentData(updatedPaymentData);
      } catch (err) {
        mainStore.goToErrorPage({
          error: new AdyenPaymentError(
            'Error in onShippingOptionsChange()!',
            err,
            advertiserStore.paymentProvider!,
          ),
        });
      }
    };

    onMounted(() => {
      initPaymentClient();
    });

    return {
      isPaymentElementReadyForRender,
      paymentsClient,
      currency: props.currency,
      countryCode: props.countryCode,
      onShippingAddressChanged,
      onShippingOptionsChange,
    };
  },
});
</script>
