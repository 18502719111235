<template>
  <div class="review-page-container">
    <h3 class="page-title">Review your order</h3>

    <span data-test="advertiser-name-title">{{ advertiserName }}</span>

    <!-- Imagery and pricing sub total -->
    <div v-if="getIsShopFrontInitialised" class="product-container" data-test="product-container">
      <div class="product-info">
        <div class="product-image">
          <img
            :src="product?.images[0].src"
            :onerror="`this.onerror=null; this.src='${placeholder()}';`"
          />
        </div>

        <div class="order-details">
          <p data-test="product-name-and-quantity">
            {{ product.name }} <span>(x{{ cart?.productQuantity }})</span>
          </p>
          <p>
            <span>Subtotal:</span
            ><span data-test="sub-total-price">{{
              getFormattedPrice(cart.pricing.totalUnitPrice)
            }}</span>
          </p>
        </div>
      </div>

      <!-- Pricing breakdown -->
      <div class="price-container" ref="totalsRef">
        <p v-if="cart?.pricing?.totalDiscount" data-test="discount-amount-output">
          <span>Discount:</span
          ><span>-{{ getFormattedPrice(Math.abs(cart.pricing.totalDiscount)) }}</span>
        </p>
        <div class="totals-and-apple-pay-container">
          <p data-test="total-amount-output">
            <span>Total:</span><span>{{ getFormattedPrice(getTotalCartPrice) }}</span>
          </p>
          <Payment />
        </div>
      </div>
    </div>

    <a
      type="button"
      @click="closePaymentPopup"
      style="text-decoration: underline; cursor: pointer"
      data-test="cancel-and-return-btn"
    >
      Cancel and return to checkout
    </a>
  </div>
</template>

<script>
import { mapState, mapActions, mapWritableState } from 'pinia';
import { useMainStore } from '@/stores/MainStore';
import gtmTracker from '@/helpers/googleTagManager.ts';
import Payment from '@/components/Payment/index.vue';
import ProductGallery from '@/components/ProductGallery/index.vue';
import Footer from '@/components/Footer/index.vue';
import { useAdvertiserStore } from '@/stores/AdvertiserStore.ts';
import { useProductStore } from '@/stores/ProductStore.ts';
import { useCartStore } from '@/stores/CartStore.ts';
import placeholder from '@/assets/placeholder.svg';

export default {
  components: {
    Payment,
    Footer,
    ProductGallery,
  },
  computed: {
    ...mapState(useMainStore, ['getIsShopFrontInitialised']),
    ...mapState(useAdvertiserStore, ['advertiserName']),
    ...mapState(useProductStore, ['product']),
    ...mapState(useCartStore, ['cart', 'getFormattedPrice', 'getTotalCartPrice']),
    ...mapWritableState(useProductStore, ['isShowStickyApplePaySummary']),
  },
  beforeUnmount() {
    this.disconnectIntersectionObservers();
  },
  methods: {
    ...mapActions(useMainStore, ['closePaymentPopup']),

    placeholder() {
      return placeholder;
    },

    initIntersectionObservers() {
      const totalsRef = this.$refs.totalsRef;

      this.disconnectIntersectionObservers();

      // Sticky Apple Pay
      if (totalsRef) {
        this.intersectionObserverStickyApplePay = new IntersectionObserver(
          (entries) => {
            this.isShowStickyApplePaySummary = entries[0].isIntersecting;
          },
          { root: document, rootMargin: '-100px' },
        );
        this.intersectionObserverStickyApplePay.observe(totalsRef);
      }
    },

    disconnectIntersectionObservers() {
      this.intersectionObserverStickyApplePay?.disconnect();
    },
  },
  mounted() {
    gtmTracker.trackJourneyEvent({
      event: 'payment_popup_loaded',
    });

    this.initIntersectionObservers();
  },
};
</script>

<style lang="scss" scoped>
@import './PaymentPopup.scss';
</style>
