<template>
  <div>
    <Totals />

    <StripeFields isShowCardPayment :doLoadStripe="loadStripe" />
  </div>
</template>

<script>
import Totals from '@/components/Totals/index.vue';
import StripeFields from '@/components/Payment/Stripe/StripeFields.vue';
import { loadStripe } from '@stripe/stripe-js';

export default {
  name: 'StripeCheckoutView',
  components: {
    Totals,
    StripeFields,
  },
  props: {
    loadStripe: {
      type: Function,
      required: true,
      default: () => {},
    },
  },
};
</script>
