<template>
  <button
    aria-label="Credit or Debit Card"
    class="button button--primary button--full-width card-payment border-rounded-small"
    @click="onClick"
    type="button"
    data-test="hosted-fields-payment-btn"
  >
    <IconVisa />
    <IconMastercard />
  </button>
</template>

<script>
import IconVisa from '@/components/icons/IconVisa.vue';
import IconMastercard from '@/components/icons/IconMastercard.vue';

export default {
  name: 'CardPaymentSelectionButton',
  components: { IconVisa, IconMastercard },
  props: {
    onClick: Function,
  },
};
</script>

<style lang="scss" scoped>
@import './styles.scss';
</style>
