<template>
  <div>
    <!-- <Totals /> -->

    <AdyenFields isShowCardPayment :adyenSdk="adyenSdk" />
  </div>
</template>

<script>
import Totals from '@/components/Totals/index.vue';
import AdyenFields from '@/components/Payment/Adyen/AdyenFields.vue';

export default {
  name: 'AdyenCheckoutView',
  components: {
    Totals,
    AdyenFields,
  },
  props: {
    adyenSdk: {
      type: Object,
      required: true,
    },
  },
};
</script>
