import type { PaymentProviderType } from '@/stores/MainStore.ts';

const BRAINTREE_SDK_VERSION = '3.111.0';

const paymentProviderScriptTags: Record<PaymentProviderType, string[]> = {
  braintree: [
    `<script src="https://www.paypalobjects.com/api/checkout.min.js" async="true" log-level="error" rel="text/javascript" data-version="4"></script>`,
    `<script src="https://js.braintreegateway.com/web/${BRAINTREE_SDK_VERSION}/js/payment-request.min.js" async="true" log-level="error" rel="text/javascript" data-version="4"></script>`,
    `<script src="https://js.braintreegateway.com/web/${BRAINTREE_SDK_VERSION}/js/hosted-fields.min.js" async="true" log-level="error" rel="text/javascript" data-version="4"></script>`,
    `<script src="https://js.braintreegateway.com/web/${BRAINTREE_SDK_VERSION}/js/three-d-secure.min.js" async="true" log-level="error" rel="text/javascript" data-version="4"></script>`,
    `<script src="https://pay.google.com/gp/p/js/pay.js" async="true" log-level="error" rel="text/javascript" data-version="4"></script>`,
    `<script src="https://js.braintreegateway.com/web/${BRAINTREE_SDK_VERSION}/js/google-payment.min.js" async="true" log-level="error" rel="text/javascript" data-version="4"></script>`,
    `<script src="https://js.braintreegateway.com/web/${BRAINTREE_SDK_VERSION}/js/client.min.js" async></script>`,
    `<script src="https://js.braintreegateway.com/web/${BRAINTREE_SDK_VERSION}/js/paypal-checkout.min.js" async></script>`,
    `<script src="https://js.braintreegateway.com/web/${BRAINTREE_SDK_VERSION}/js/apple-pay.min.js" async></script>`,
  ],
  adyen: [
    `<script src="${import.meta.env.VITE_ADYEN_SCRIPT_DOMAIN}/checkoutshopper/sdk/6.2.0/adyen.js" integrity="sha384-D2a9elrzh5aA3ZKUHNy1El71tHftlGcJParXoQyaBDcwmcsWn8y8TFjT3axXIiig" crossorigin="anonymous"></script>`,
  ],
  stripe: [],
};

function getAttributesFromTagString(tagString: string) {
  // Create a temporary DOM element
  const tempDiv = document.createElement('div');

  // Insert the string into the temporary element
  tempDiv.innerHTML = tagString;

  // Get the first child, which is the <script> tag
  const scriptTag = tempDiv.querySelector('script');

  // If no script tag is found, return an empty array
  if (!scriptTag) {
    return [];
  }

  // Get all attributes of the <script> tag
  const attributes = scriptTag.attributes;

  // Convert the NamedNodeMap to an array of objects (name-value pairs)
  const attributesArray = Array.from(attributes).map((attr) => ({
    name: attr.name,
    value: attr.value,
  }));

  return attributesArray;
}

const renderScriptTagToPage = (attributes: { name: string; value: string }[]) => {
  const script = document.createElement('script');

  attributes.forEach(({ name, value }) => script.setAttribute(name, value));
  document.head.appendChild(script);
};

// @throws
export const loadPaymentProviderScripts = (paymentProvider: PaymentProviderType) => {
  const scriptTags: string[] = paymentProviderScriptTags[paymentProvider];

  if (!scriptTags) {
    throw new Error(
      `Could not find associated script tags for payment provider ${paymentProvider}!`,
    );
  }

  scriptTags.forEach((scriptTag) => renderScriptTagToPage(getAttributesFromTagString(scriptTag)));
};
