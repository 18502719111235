<template>
  <div v-if="isReadyForRender">
    <!-- TODO allowed country codes -->
    <!-- Express Checkout -->
    <AdyenFieldsExpressCheckout
      v-if="isShowExpressCheckout"
      data-test="express-payment-component"
      :onReady="onPaymentComponentReady"
      :adyenSdk="adyenSdk"
      :currency="currency"
      :paymentMethodsResponseData="paymentMethodsResponseData!"
      :availablePaymentMethods="availablePaymentMethods"
      :countryCode="countryCode"
      :locale="locale"
    />

    <!-- Card Payment -->
    <AdyenFieldsCardPayment
      v-if="isShowCardPayment && availablePaymentMethods.includes('Cards')"
      data-test="card-payment-component"
      :onReady="onPaymentComponentReady"
      :adyenSdk="adyenSdk"
      :currency="currency"
      :paymentMethodsResponseData="paymentMethodsResponseData!"
      :countryCode="countryCode"
      :locale="locale"
    />
  </div>
</template>

<script lang="ts">
import { defineComponent, ref, computed, onBeforeMount, onMounted } from 'vue';

import AdyenFieldsExpressCheckout from '@/components/Payment/Adyen/Express/AdyenFieldsExpressCheckout.vue';
import AdyenFieldsCardPayment from '@/components/Payment/Adyen/Card/AdyenFieldsCardPayment.vue';

import { useMainStore } from '@/stores/MainStore';
import { useCartStore } from '@/stores/CartStore';
import { useAdvertiserStore } from '@/stores/AdvertiserStore';

import { Api as PaymentApi } from '@/api/payment/adyen';
import constants from '@/constants';

// TEMP, just for quik loading card screen in development
import { loadPaymentProviderScripts } from '@/helpers/scriptInjection';

import type {
  AdyenAvailablePaymentMethod,
  AdyenPaymentMethodsType,
} from '@/types/payment.adyen.types';
import type { PaymentButtonTypeAdyen } from '@/stores/MainStore';

export default defineComponent({
  name: 'AdyenFields',
  components: {
    AdyenFieldsExpressCheckout,
    AdyenFieldsCardPayment,
  },
  props: {
    adyenSdk: Object, // TODO see Braintree components for how we need to wait for scripts to be loaded (i.e. using awaitInstanceInitialised())
    isShowExpressCheckout: Boolean,
    isShowCardPayment: Boolean,
  },
  setup(props) {
    const mainStore = useMainStore();
    const cartStore = useCartStore();
    const advertiserStore = useAdvertiserStore();

    const currency = mainStore.currency.currencyCode;
    const totalPaymentPrice = computed(() => cartStore.getTotalPaymentPrice);

    const isReadyForRender = ref(false);
    const paymentMethodsResponseData = ref<{ paymentMethods: AdyenAvailablePaymentMethod[] }>();
    const availablePaymentMethods = ref<AdyenPaymentMethodsType[]>([]);

    const init = async () => {
      const paymentMethodsResponse = await PaymentApi.getPaymentMethods({
        amount: {
          value: totalPaymentPrice.value * 100,
          currency,
        },
      });

      // ///////////////////////////////////////////////////////////////////////////////////////////////////////////////
      // ///////////////////////////////////////////////////////////////////////////////////////////////////////////////
      // TODO once updated from main branch, augment this with the value of 'enabledPaymentMethods' in the Payment Store.
      // ///////////////////////////////////////////////////////////////////////////////////////////////////////////////
      // ///////////////////////////////////////////////////////////////////////////////////////////////////////////////

      paymentMethodsResponseData.value = paymentMethodsResponse;
      availablePaymentMethods.value = paymentMethodsResponse.paymentMethods.map(({ name }) => name);

      isReadyForRender.value = true;
    };

    /*
    const isCardPaymentFlow = async () => {
      const router = await getRouter();
      return router.currentRoute.value.name === 'AdyenCheckout';
    };
    */

    const onPaymentComponentReady = async (componentType: PaymentButtonTypeAdyen) => {
      console.log('onPaymentComponentReady()', componentType);
      mainStore.setPaymentButtonInitialised(
        advertiserStore.paymentProvider!,
        componentType,
        'INITIALISED',
      );
    };

    onBeforeMount(() => {
      loadPaymentProviderScripts('adyen');
    });

    onMounted(() => {
      init();
    });

    return {
      isReadyForRender,
      paymentMethodsResponseData,
      availablePaymentMethods,
      onPaymentComponentReady,
      currency,
      countryCode: constants.PAYMENT_PROCESSING_COUNTRY_CODE,
      locale: 'en-GB',
    };
  },
});
</script>

<style lang="css">
@import './adyen.css';
@import './adyen-overrides.scss';
</style>
