import api from '../../Api';
import constants from '@/constants';

import type {
  AdyenAvailablePaymentMethod,
  AdyenShippingMethodType,
  AdyenPaymentMethodsType,
} from '@/types/payment.adyen.types';

const mockServerUrl = `https://${import.meta.env.VITE_LOCAL_NODE_SERVER_URL}`;

const getPaymentMethods = async ({
  amount,
}: {
  amount: {
    value: number;
    currency: string;
  };
}): Promise<{ paymentMethods: AdyenAvailablePaymentMethod[] }> => {
  const result = await api.post(
    `init-adyen-flow`,
    {
      countryCode: constants.PAYMENT_PROCESSING_COUNTRY_CODE,
      shopperLocale: 'en-GB',
      amount,
    },
    {},
    mockServerUrl,
  );

  return result.data;
};

const submitPayment = async ({
  amount,
  paymentMethod,
}: {
  amount: {
    value: number;
    currency: string;
  };
  paymentMethod: AdyenPaymentMethodsType;
}): Promise<ToDo> => {
  try {
    const result = await api.post(
      `submit-adyen-payment`,
      {
        amount,
        paymentMethod,
      },
      {},
      mockServerUrl,
    );

    if (!result.data.resultCode) {
      throw new Error('Missing result code in response!');
    }

    return result.data;
  } catch (err) {
    console.log('API Submission error:', err);

    if (err.response?.status === 400) {
      // I.e. be a validation error
      return { error: err.response?.data.error };
    }
  }
};

const submitPayPalPaymentDetails = async ({
  details,
  paymentData,
}: {
  details: ToDo;
  paymentData: string;
}): Promise<ToDo> => {
  const result = await api.post(
    `submit-adyen-payment-details`,
    {
      details,
      paymentData,
    },
    {},
    mockServerUrl,
  );

  if (!result.data.resultCode) {
    throw new Error('Missing result code in response!');
  }

  return result.data;
};

const updatePayPalOrder = async ({
  shopthruPspReference,
  paymentData,
  totalOrderPrice,
  shippingOptions,
}: {
  shopthruPspReference: string;
  paymentData: string;
  totalOrderPrice: number;
  shippingOptions?: AdyenShippingMethodType[];
}): Promise<{ paymentData: string }> => {
  const payload = {
    shopthruPspReference,
    paymentData,
    totalOrderPrice,
    shippingOptions,
  };

  if (!shippingOptions) {
    delete payload.shippingOptions;
  }

  const result = await api.post(`update-adyen-paypal-order`, payload, {}, mockServerUrl);

  if (result.data.errorCode) {
    console.error('Update error data:', result.data);
    throw new Error('An error occurred in updating the order!');
  }

  return result.data;
};

export const Api = {
  getPaymentMethods,
  submitPayment,
  submitPayPalPaymentDetails,
  updatePayPalOrder,
};
