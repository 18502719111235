import type { PaymentProviderType } from '@/stores/MainStore';

export class ShopthruError extends Error {
  sourceError;

  constructor(message: string, error?: unknown) {
    super(message);
    this.name = 'ShopthruError';
    this.sourceError = error;

    console.error('ShopthruError thrown - ', message, error);
  }
}

/**
 * PAYMENT
 */
export class PaymentError extends ShopthruError {
  paymentProvider: PaymentProviderType;

  constructor(message: string, error: unknown, paymentProvider: PaymentProviderType) {
    super(message, error);
    this.paymentProvider = paymentProvider;
    this.name = 'PaymentError';
  }
}

export class GooglePaymentError extends PaymentError {
  constructor(message: string, error: unknown, paymentProvider: PaymentProviderType) {
    super(message, error, paymentProvider);
    this.name = 'GooglePaymentError';
  }
}

export class ApplePaymentError extends PaymentError {
  constructor(message: string, error: unknown, paymentProvider: PaymentProviderType) {
    super(message, error, paymentProvider);
    this.name = 'ApplePaymentError';
  }
}

export class PayPalPaymentError extends PaymentError {
  constructor(message: string, error: unknown, paymentProvider: PaymentProviderType) {
    super(message, error, paymentProvider);
    this.name = 'PayPalPaymentError';
  }
}

export class HostedFieldsPaymentError extends PaymentError {
  constructor(message: string, error: unknown, paymentProvider: PaymentProviderType) {
    super(message, error, paymentProvider);
    this.name = 'HostedFieldsPaymentError';
  }
}

export class StripePaymentError extends PaymentError {
  constructor(message: string, error: unknown, paymentProvider: PaymentProviderType) {
    super(message, error, paymentProvider);
    this.name = 'StripePaymentError';
  }
}

export class AdyenPaymentError extends PaymentError {
  constructor(message: string, error: unknown, paymentProvider: PaymentProviderType) {
    super(message, error, paymentProvider);
    this.name = 'AdyenPaymentError';
  }
}

/**
 * SHIPPING
 */
export class ShippingError extends ShopthruError {
  constructor(message: string, error: unknown) {
    super(message, error);
    this.name = 'ShippingError';
  }
}

/**
 * ERROR PAGE
 */
export class ErrorBoundaryPageInferredError extends ShopthruError {
  constructor(message: string) {
    super(message);
    this.name = 'ErrorBoundaryPageInferredError';
  }
}

export class ErrorBoundaryPageGenericError extends ShopthruError {
  constructor(message: string) {
    super(message);
    this.name = 'ErrorBoundaryPageGenericError';
  }
}

/**
 * PRODUCT VARIANTS
 */
export class ProductVariantsLookupError extends ShopthruError {
  constructor(message: string) {
    super(message);
    this.name = 'ProductVariantsLookupError';
  }
}

/**
 * CUSTOMER RECORD
 */
export class CustomerRecordError extends ShopthruError {
  constructor(message: string) {
    super(message);
    this.name = 'CustomerRecordError';
  }
}

/**
 * PRODUCT
 */
export class InvalidProductCodeError extends ShopthruError {
  constructor() {
    super('Invalid Product Code!');
    this.name = 'InvalidProductCodeError';
  }
}

/**
 * CART
 */
export class CartInitError extends ShopthruError {
  constructor(message: string) {
    super(message);
    this.name = 'InvalidProductCodeError';
  }
}
