<template>
  <div id="payment-buttons-container">
    <div
      v-show="isPaymentButtonsInitialised"
      :class="{
        'is-visible-just-for-unit-test': isPaymentButtonsInitialised,
      }"
      data-test="payment-buttons-is-initialised-wrapper"
    >
      <div v-if="transaction.token" data-test="payment-buttons-is-token-set-wrapper">
        <!-- Adyen fields-->
        <div v-if="paymentProvider === 'adyen'" data-test="payment-buttons-is-adyen-wrapper">
          <AdyenFields isShowExpressCheckout :adyenSdk="adyenSdk" />

          <CardPaymentSelectionButton :onClick="progressToAdyenCardPaymentFlow" />
        </div>

        <!-- Stripe fields-->
        <div v-if="paymentProvider === 'stripe'" data-test="payment-buttons-is-stripe-wrapper">
          <!-- TODO disable card option here -->
          <StripeFields isShowExpressCheckout :doLoadStripe="loadStripe" />

          <CardPaymentSelectionButton :onClick="progressToStripeCardPaymentFlow" />
        </div>

        <!-- Braintree fields-->
        <div
          v-if="paymentProvider === 'braintree'"
          class="text-align-center"
          data-test="payment-buttons-is-braintree-wrapper"
        >
          <BraintreeApplePay
            v-if="isRenderPaymentButton('apple')"
            v-show="isPaymentButtonInitialisedSuccessfully('apple')"
            data-test="pay-btn-apple-pay"
          />
          <BraintreeGooglePay
            v-if="isRenderPaymentButton('google')"
            :googleApi="googleApi"
            :braintreeApi="braintreeApi"
            v-show="isPaymentButtonInitialisedSuccessfully('google')"
          />
          <BraintreePaypal
            v-if="isRenderPaymentButton('paypal')"
            :paypalApi="paypalApi"
            :braintreeApi="braintreeApi"
            v-show="isPaymentButtonInitialisedSuccessfully('paypal')"
          />

          <div
            v-if="
              isPaymentButtonInitialisedSuccessfully('hostedFields') &&
              isRenderPaymentButton('hostedFields')
            "
          >
            <CardPaymentSelectionButton :onClick="progressToBraintreeCardPaymentFlow" />
          </div>
        </div>
      </div>
    </div>

    <div v-show="!isPaymentButtonsInitialised" class="loading-message-container">
      <lottie-animation :animationData="loaderAnimation" :loop="true" />

      <span>Loading payment options...</span>
    </div>
  </div>
</template>

<script>
import { mapState, mapActions } from 'pinia';

import { useAdvertiserStore } from '@/stores/AdvertiserStore';
import { useMainStore } from '@/stores/MainStore.ts';
import { usePaymentStore } from '@/stores/PaymentStore';

import BraintreeGooglePay from '@/components/Payment/Braintree/BraintreeGooglePay/index.vue';
import BraintreeApplePay from '@/components/Payment/Braintree/BraintreeApplePay/index.vue';
import BraintreePaypal from '@/components/Payment/Braintree/BraintreePaypal/index.vue';
import AdyenFields from '@/components/Payment/Adyen/AdyenFields.vue';
import StripeFields from '@/components/Payment/Stripe/StripeFields.vue';
// //// import AdyenGooglePay from '@/components/Payment/Adyen/AdyenGooglePay/index.vue';
import CardPaymentSelectionButton from '@/components/Payment/CardPaymentSelectionButton.vue';

import loader from '@/assets/loader.json';

import { getRouter } from '@/router';
import constants from '@/constants';

export default {
  name: 'PaymentComponent',
  components: {
    BraintreeGooglePay,
    BraintreeApplePay,
    BraintreePaypal,
    AdyenFields,
    StripeFields,
    CardPaymentSelectionButton,
  },
  props: {
    loadStripe: {
      type: Function,
      required: true,
      default: () => {},
    },
  },
  data() {
    return {
      open: false,
      googleApi: window.google,
      paypalApi: window.paypal,
      braintreeApi: window.braintree,
      adyenSdk: window.AdyenWeb,
      paymentButtonInitialisationTimerId: null,
      paymentButtonInitialisationTimerExpired: false,
    };
  },
  mounted() {
    this.startPaymentButtonInitialisationTimer();
  },
  beforeUnmount() {
    if (this.paymentButtonInitialisationTimerId) {
      clearTimeout(this.paymentButtonInitialisationTimerId);
    }
  },
  computed: {
    ...mapState(useAdvertiserStore, ['companyName', 'advertiserTerms', 'paymentProvider']),
    ...mapState(usePaymentStore, ['transaction']),
    ...mapState(useMainStore, [
      'supports',
      'isPaymentButtonsInitialised',
      'initialisedPaymentButtons',
      'isPaymentPopup',
      'paymentPopupState',
    ]),

    loaderAnimation() {
      return loader;
    },
  },
  methods: {
    ...mapActions(useMainStore, [
      'redirectToView',
      'assessAvailablePaymentButtonsAfterInitialisationAttempted',
    ]),

    async progressToBraintreeCardPaymentFlow() {
      const router = await getRouter();
      this.redirectToView('/shipping', router.push);
    },

    async progressToStripeCardPaymentFlow() {
      const router = await getRouter();
      this.redirectToView('/stripe/checkout', router.push);
    },

    async progressToAdyenCardPaymentFlow() {
      const router = await getRouter();
      this.redirectToView('/shipping', router.push);
    },

    /**
     * Wait a finite amount of time before deciding to conditionally render payment buttons based on whether they successfully initialised in
     * an arbritray but reasonable amount of time. I.e. upon this timeout, then only render buttons which initialised, or rather don't let one failed
     * button prevent any of them from showing.
     */
    startPaymentButtonInitialisationTimer() {
      this.paymentButtonInitialisationTimerId = setTimeout(() => {
        this.assessAvailablePaymentButtonsAfterInitialisationAttempted(this.paymentProvider);
        this.paymentButtonInitialisationTimerExpired = true;
      }, constants.PAYMENT_BTN_INITIALISATION_CHECK_TIMEOUT);
    },

    isPaymentButtonInitialisedSuccessfully(flag) {
      return this.initialisedPaymentButtons[this.paymentProvider][flag] === 'INITIALISED';
    },

    isRenderPaymentButton(paymentMethod) {
      let rtn = true;

      switch (paymentMethod) {
        case 'apple':
          rtn = this.supports.applePay;
          break;

        case 'google':
          if (
            this.isRenderPaymentButton('apple') &&
            this.isPaymentButtonInitialisedSuccessfully('apple')
          ) {
            // Only render Google Pay on Apple Pay devices if Apple Pay failed to initialise.
            rtn = false;
          }
          break;

        default:
          break;
      }

      return rtn && this.isShowPaymentButtonIfInPopup(paymentMethod);
    },

    isShowPaymentButtonIfInPopup(paymentMethod) {
      if (this.isPaymentPopup) {
        return this.paymentPopupState.paymentMethods.includes(paymentMethod);
      }

      return true;
    },
  },
};
</script>

<style lang="scss" scoped>
@import './styles.scss';
</style>
