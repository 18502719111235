import { defineStore } from 'pinia';
import { useAdvertiserStore } from '@/stores/AdvertiserStore';
import { usePublisherStore } from '@/stores/PublisherStore.js';
import { useMainStore, type PaymentProviderType } from '@/stores/MainStore.js';
import { getProduct, getProductPricingAndAvailability } from '@/api/product';
import {
  ProductPricingAndAvailablitySimple,
  ProductPricingAndAvailablityConfigurable,
  type ProductBase,
  type ProductPricingAndAvailablitySimpleProps,
  type ProductPricingAndAvailablityConfigurableProps,
} from '@/types/product.types';

import { getRouter } from '@/router';
import { ProductVariantsLookupError, InvalidProductCodeError } from '@/types/errors.types';
import { getOptionedProductHash } from '@/helpers/productVariants';
import { Utils } from '@/helpers/utils';
import { loadPaymentProviderScripts } from '@/helpers/scriptInjection';

type DiscountLookupState =
  | null
  | 'LOOKUP_IN_PROGRESS'
  | 'LOOKUP_ERRED'
  | 'DISCOUNT_APPLIED'
  | 'DISCOUNT_NOT_APPLICABLE'
  | 'DISCOUNT_REVOKED';

type State = {
  product: ProductBase | null;
  productPricingAndAvailability:
    | ProductPricingAndAvailablitySimple
    | ProductPricingAndAvailablityConfigurable
    | null;
  productDescriptionOpen: boolean;
  productGalleryOpen: boolean;
  discountLookupState: DiscountLookupState;
  isShowStickyProductSummary: boolean;
  isShowStickyProductSummaryExtended: boolean;
  isShowStickyApplePaySummary: boolean;
  products: []; // TODO
  productVariants: []; // TODO
  isProductHasVariants: boolean;
  variantsMatrix: Record<string, ToDo>;
  isProductsInitialised: boolean;
};

const getInitialState = (): State => ({
  product: null,
  productPricingAndAvailability: null,
  productDescriptionOpen: false,
  productGalleryOpen: false,
  discountLookupState: null,
  isShowStickyProductSummary: false,
  isShowStickyProductSummaryExtended: false,
  isShowStickyApplePaySummary: false,
  products: [],
  productVariants: [],
  isProductHasVariants: false,
  variantsMatrix: {},
  isProductsInitialised: false,
});

export const useProductStore = defineStore('productStore', {
  state: (): State => {
    return getInitialState();
  },
  actions: {
    async fetchProductData(productCode: string) {
      const router = await getRouter();
      const advertiserStore = useAdvertiserStore();
      const publisherStore = usePublisherStore();
      const mainStore = useMainStore();
      let productLookupResult;

      try {
        productLookupResult = await getProduct(productCode);
      } catch (err) {
        if (err.response?.status === 404) {
          let responseData;

          console.info('Product could not be found on lookup!', err);

          // TODO remove when back end updated (corrected...):
          if (err.response?.data?.data) {
            responseData = err.response?.data?.data;
          } else {
            responseData = err.response?.data;
          }

          if (responseData.retailer) {
            // The product code is valid
            advertiserStore.setData(responseData.retailer);
            mainStore.redirectToView('/product-not-found', router.replace);

            return Promise.reject('Product not found, redirecting to Product Not Found page...');
          } else {
            // The product code is not valid
            mainStore.code = null;
            mainStore.goToErrorPage({
              error: new InvalidProductCodeError(),
            });

            return Promise.reject('Product code invalid, redirecting to Error page...');
          }
        } else {
          throw err;
        }
      }

      const { product, retailer, publisher } = productLookupResult!.data.data;

      this.product = {
        id: product.id,
        name: product.name,
        shortDescription: product.short_description,
        description: product.description,
        images: product.images || [],
        displayAttributes: product.display_attributes,
        sku: product.sku,
        fallbackUrl: product.fallback_url,
      } as ProductBase;

      this.isProductHasVariants = !!product.variants.products?.length;

      if (this.isProductHasVariants) {
        this.products = product.variants.products; // TODO not sure about the naming...
        this.productVariants = product.variants.options; // TODO not sure about the naming...
      }

      this.initVariantsMatrix();

      advertiserStore.setData(retailer);
      publisherStore.setData(publisher);

      // Start downloading the required scripts for this payment provider
      loadPaymentProviderScripts(advertiserStore.paymentProvider as PaymentProviderType);

      if (mainStore.isPaymentPopup) {
        this.isProductsInitialised = true;
      }
    },

    async fetchProductPricingAndAvailability(productCode: string) {
      const result: {
        data: {
          product:
            | ProductPricingAndAvailablitySimpleProps
            | ProductPricingAndAvailablityConfigurableProps;
        };
      } = await getProductPricingAndAvailability(productCode);

      const { product: pricingAndAvailability } = result.data;

      if (this.isProductHasVariants) {
        const prAndAvail = pricingAndAvailability as ProductPricingAndAvailablityConfigurableProps;

        this.productPricingAndAvailability = new ProductPricingAndAvailablityConfigurable(
          prAndAvail,
        );

        this.updateVariantsMatrixWithAvailability();
      } else {
        const prAndAvail = pricingAndAvailability as ProductPricingAndAvailablitySimpleProps;

        this.productPricingAndAvailability = new ProductPricingAndAvailablitySimple(prAndAvail);
      }

      this.isProductsInitialised = true;
    },

    initVariantsMatrix() {
      if (this.isProductHasVariants) {
        if (!this.products?.length || !this.productVariants?.length) {
          throw new Error(
            'Product was specified as having variants but incomplete variant data was supplied!',
          );
        }

        this.variantsMatrix = this.products.reduce((acc, cur) => {
          const key = getOptionedProductHash(
            cur.variantOptions.map(({ variantCode, variantId }) => ({
              variantCode,
              val: variantId,
            })),
          );

          acc[key] = cur;
          return acc;
        }, {});
      }
    },

    updateVariantsMatrixWithAvailability() {
      const prAndAvail = this
        .productPricingAndAvailability as ProductPricingAndAvailablityConfigurable;

      this.variantsMatrix = Object.entries(this.variantsMatrix).reduce(
        (acc, [productHash, productProps]) => {
          const matchedPrAndAvailVariant = prAndAvail.variants.find(
            (variant) => variant.sku === productProps.sku,
          );

          if (!matchedPrAndAvailVariant) {
            throw new Error('Could not match matrix SKU to pricing and availability sku!');
          }

          acc[productHash] = {
            ...productProps,
            isInStock: matchedPrAndAvailVariant.isInStock,
          };

          return acc;
        },
        {} as Record<string, ToDo>,
      );
    },

    closeGalleryOverlay() {
      this.productGalleryOpen = false;
    },

    toggleGalleryOverlay() {
      Utils.debounce(() => (this.productGalleryOpen = !this.productGalleryOpen), 100);
    },

    setDiscountLookupState(discountState: DiscountLookupState) {
      this.discountLookupState = discountState;
    },
  },
  getters: {
    getProductUiOptions: ({ productVariants }) => {
      return productVariants.map((productVariant) => ({
        optionCode: productVariant.variantCode,
        label: productVariant.label,
        values: productVariant.values.map((val) => ({
          id: val.variantId,
          label: val.label,
        })),
      }));
    },

    getProductVariantFromOptions: (state) => {
      return (productOptions: Record<string, string>) => {
        let productVariant;

        const productHash = getOptionedProductHash(
          Object.entries(productOptions).map(([variantCode, optionValue]) => ({
            variantCode,
            val: optionValue,
          })),
        );

        productVariant = state.variantsMatrix[productHash];

        if (!productVariant) {
          throw new ProductVariantsLookupError(
            `Failed to get Product from Options, unable to match Product by hash - ${productHash}!`,
          );
        }

        return productVariant;
      };
    },

    getOptionsFromProductHash: (state) => {
      return (productHash: string) => {
        const product = state.variantsMatrix[productHash];

        if (!product) {
          throw new ProductVariantsLookupError(
            `Failed to get Options from Product Hash - ${productHash}!`,
          );
        }

        return product.variantOptions.map(
          ({ variantCode, variantId }: { variantCode: string; variantId: string }) => ({
            variantCode,
            variantId,
          }),
        );
      };
    },

    getPricingAndAvailabilityByVariantSku: (state) => {
      return (sku: string) => {
        return (
          state.productPricingAndAvailability as ProductPricingAndAvailablityConfigurable
        ).variants.find((v) => v.sku === sku);
      };
    },

    getIsAnyProductVariantAvailable(state): () => boolean {
      return () =>
        (state.productPricingAndAvailability as ProductPricingAndAvailablityConfigurable)!.variants.some(
          (v) => v.isInStock,
        );
    },
  },
});
