<template>
  <div class="notification notification--error">
    <div class="notification__icon mb-5">
      <lottie-animation :animationData="errorAnimation" :loop="true" />
    </div>

    <div class="notification-text">
      <h2 v-text="messaging.message.title" data-test="error-msg-title" />

      <p
        v-html="messaging.message.subTitle"
        class="font-weight-light"
        data-test="error-msg-subtitle"
      />
    </div>

    <!-- Buttons -->
    <div v-for="(btn, i) in messaging.btns" :key="`button_${i}`" class="mt-15">
      <button
        type="button"
        @click="btn.onClick"
        :class="{
          button: true,
          'button--primary': btn.priority === 'primary',
          'button--secondary': btn.priority === 'secondary',
          'button--tertiary': btn.priority === 'tertiary',
        }"
        data-test="error-action-button"
      >
        {{ btn.text }}
      </button>
    </div>
  </div>
</template>

<script>
import { mapState, mapActions } from 'pinia';
import { getRouter } from '@/router';
import * as Sentry from '@sentry/vue';
import errorAnimation from '@/assets/lottie/error.json';
import {
  ErrorBoundaryPageInferredError,
  ErrorBoundaryPageGenericError,
} from '@/types/errors.types.ts';
import { useMainStore } from '@/stores/MainStore.ts';
import gtmTracker from '@/helpers/googleTagManager.ts';

/**
 * This file is intended to have minimal application dependancies in notifying the user of an error (that has broken the checkout journey).
 * This is done so that we don't make calls to things like Stores where the original error may have occured and end up in a cyclic state.
 */
export default {
  name: 'Error',
  created() {
    gtmTracker.trackJourneyEvent({
      event: 'error_page',
    });
  },
  mounted() {
    const isSkipLogToSentry = this.$route.query.isSkipLogToSentry;

    if (!isSkipLogToSentry) {
      /**
       * Most of the time we'll arrive on this page from the global error boundary, where the Sentry log will already have been made.
       * However if we've come here explicitly via some other router call then send what error information we get from the URL.
       */
      Sentry.captureException(new ErrorBoundaryPageInferredError(JSON.stringify(this.error)));
    }

    this.endAppLoading();

    if (['local', 'dev', 'staging'].includes(import.meta.env.VITE_APP_ENV)) {
      console.info('Dev note - Error page reached with error:', this.error);
    }

    if (!this.getFallbackUrl) {
      const msg = 'Could not find fallback URL on reaching Error page!';
      console.error(msg);
      Sentry.captureException(msg);
    }
  },
  computed: {
    ...mapState(useMainStore, ['getFallbackUrl']),

    isPaymentError() {
      return [
        'GooglePaymentError',
        'ApplePaymentError',
        'PayPalPaymentError',
        'HostedFieldsPaymentError',
        'StripePaymentError',
        'AdyenPaymentError',
      ].includes(this.error?.name);
    },

    errorAnimation() {
      return errorAnimation;
    },

    error() {
      try {
        const error = this.$route.query.error;

        if (error) {
          return JSON.parse(error);
        }
      } catch (err) {
        console.error('Failed to parse error!');
        Sentry.captureException(err);
      }
    },

    messaging() {
      const shortCode = this.$route.query.shortCode;

      const buttonProps = {
        closeCheckout: {
          onClick: this.closeCheckout,
          priority: 'secondary',
          text: 'Close Checkout',
        },
        goToRetailer: {
          onClick: this.goToFallbackUrl,
          priority: 'tertiary',
          text: 'Go to Retailer',
        },
        goDirectToRetailer: {
          onClick: this.goToFallbackUrl,
          priority: 'secondary',
          text: 'Go direct to Retailer',
        },
        restartCheckout: {
          onClick: this.restartCheckout,
          priority: 'secondary',
          text: 'Restart Checkout',
        },
      };

      const message = {
        title: 'Something has gone wrong!',
        subTitle: 'We apologise for the inconvenience',
      };

      const btns = [];

      // No Product Short Code
      if (!shortCode) {
        message.title = 'Unable to create Checkout';
        message.subTitle = 'We apologise for the inconvenience';
        btns.push(buttonProps.closeCheckout);
      } else {
        // Payment Error
        if (this.isPaymentError) {
          const mainMsg =
            'Unfortunately there was a problem processing your payment and your order could not be placed';

          message.title = 'Your payment was unsuccessful';
          message.subTitle = mainMsg;
          btns.push(buttonProps.restartCheckout);

          if (this.getFallbackUrl) {
            message.subTitle = `${mainMsg}<br /><br />Please try again or visit the retailer`;
            btns.push(buttonProps.goToRetailer);
          }
        }

        // Generic Error
        else {
          btns.push(buttonProps.restartCheckout);
          btns.push(buttonProps.closeCheckout);
        }
      }

      return { message, btns };
    },
  },

  methods: {
    ...mapActions(useMainStore, ['endAppLoading']),

    goToFallbackUrl() {
      try {
        window.open(this.getFallbackUrl, '_blank');
      } catch (err) {
        console.error('Failed to open fallback URL!');
        Sentry.captureException(err);
        return;
      }

      this.closeCheckout();
    },

    async restartCheckout() {
      const shortCode = this.$route.query.shortCode;
      const router = await getRouter();

      if (shortCode) {
        return router.replace(`/${shortCode}`);
      }

      const failureMsg = 'Could not find short code to restart Checkout!';

      console.error(failureMsg);
      Sentry.captureException(new ErrorBoundaryPageGenericError(failureMsg));
    },

    closeCheckout() {
      try {
        parent.window.postMessage('closeCheckout', '*'); // TODO this is all old hat now
      } catch (err) {
        console.error('Failed to close Checkout!');
        Sentry.captureException(err);
      }
    },
  },
};
</script>

<style lang="scss" scoped>
@import './Notification.scss';
</style>
