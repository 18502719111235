export const ExampleProduct = {
  id: 'id-1',
  name: 'Example Product',
  description: 'This is an example product!',
  images: [
    {
      caption: 'An image!',
      src: 'https://shopthru.com/wp-content/uploads/2023/11/LOGO.svg',
    },
    {
      caption: 'Another image!',
      src: 'https://shopthru.com/wp-content/uploads/elementor/thumbs/Yello-1-PhotoRoom-PhotoRoom-4-qh38ij8o2dnq0o9urcwxlfl7tq73fyd9pcf0mtzxku.png',
    },
  ],
  displayAttributes: [
    {
      name: 'Display attribute 1',
      code: 'attr-1',
      value: 'A value 1',
      additional_text: 'Additional text 1',
    },
    {
      name: 'Display attribute 2',
      code: 'attr-2',
      value: 'A value 2',
      additional_text: 'Additional text 2',
    },
  ],
};

export type ProductBase = {
  id: string;
  name: string;
  shortDescription: string;
  description: string;
  images: { caption: string; src: string }[];
  displayAttributes: {
    name: string;
    code: string;
    value: string;
    additional_text: string;
  }[];
  sku: string;
  fallbackUrl: string;
};

export type ProductPricingAndAvailablitySimpleProps = {
  sku: string;
  name: string;
  isInStock: boolean;
  priceRegular: number;
  priceSpecial?: number;
};

export type ProductPricingAndAvailablityConfigurableProps = {
  sku: string;
  name: string;
  isInStock: boolean;
  priceMin: number;
  priceMax: number;
  variants: ProductPricingAndAvailablityVariant[];
};

export type ProductPricingAndAvailablityVariant = {
  sku: string;
  name: string;
  isInStock: boolean;
  priceRegular: number;
  priceSpecial?: number;
};

// Product with no variants
export class ProductPricingAndAvailablitySimple {
  sku: string;
  name: string;
  isInStock: boolean;
  priceRegular: number;
  priceSpecial?: number;

  constructor(props: ProductPricingAndAvailablitySimpleProps) {
    this.sku = props.sku;
    this.name = props.name;
    this.isInStock = props.isInStock;
    this.priceRegular = props.priceRegular;
    this.priceSpecial = props.priceSpecial;
  }
}

// Product with variants
export class ProductPricingAndAvailablityConfigurable {
  sku: string;
  name: string;
  isInStock: boolean;
  priceMin: number;
  priceMax: number;
  variants: ProductPricingAndAvailablityVariant[];

  constructor(props: ProductPricingAndAvailablityConfigurableProps) {
    this.sku = props.sku;
    this.name = props.name;
    this.isInStock = props.isInStock;
    this.priceMin = props.priceMin;
    this.priceMax = props.priceMax;
    this.variants = props.variants;
  }
}
