import { defineStore } from 'pinia';
import { useMainStore } from '@/stores/MainStore';
import { useCartStore } from '@/stores/CartStore';
import { useAdvertiserStore } from '@/stores/AdvertiserStore';
import { isValidEmailAddress, isValidPhoneNumber, isValidPostcode } from '@/helpers/validation';
import { ShippingError } from '@/types/errors.types';

export type ShippingMethod = {
  label: string;
  code: string;
  amount: number;
};

export type AddressType = {
  streetAddress: string[];
  city: string;
  postalCode: string;
  region?: string;
  country: string;
};

export type AddressTypeEnum = 'billing' | 'shipping';

export type State = {
  address: {
    customer: {
      shipping: {
        firstName: string;
        lastName: string;
        email: string;
        phone: string;
      };
      billing: {
        firstName: string;
        lastName: string;
        email: string;
        phone: string;
      };
    };
    shipping: {
      streetAddress: string[];
      city: string;
      postalCode: string;
      region?: string;
      country: string;
    };
    billing: {
      streetAddress: string[];
      city: string;
      postalCode: string;
      region?: string;
      country: string;
    };
    saved: {
      shipping: boolean;
      billing: boolean;
    };
    editing: {
      shipping: boolean;
      billing: boolean;
    };
    errors: {
      shipping: {
        fields: string[];
        message: string | null;
      };
      billing: {
        fields: string[];
        message: string | null;
      };
    };
  };
  billingIsShipping: boolean;
  isShippingMethodSelectionInProgress: boolean;
  shippingMethods: ShippingMethod[] | null;
};

const fields = {
  firstName: 'First name',
  lastName: 'Last name',
  phone: 'Telephone',
  streetAddress: 'Address Line',
  city: 'City',
  country: 'Country',
  postalCode: 'Postcode',
  region: 'Region',
  email: 'Email Address',
};

export const getInitialState = (): State => {
  const rtn = {
    address: {
      customer: {
        shipping: {
          firstName: null,
          lastName: null,
          email: null,
          phone: null,
        },
        billing: { firstName: null, lastName: null, phone: null },
      },
      shipping: {
        streetAddress: [],
        city: null,
        postalCode: null,
        region: null,
        country: null,
      },
      billing: {
        streetAddress: [],
        city: null,
        postalCode: null,
        region: null,
        country: null,
      },
      saved: {
        shipping: false,
        billing: false,
      },
      editing: {
        shipping: false,
        billing: false,
      },
      errors: {
        shipping: {
          fields: [],
          message: null,
        },
        billing: {
          fields: [],
          message: null,
        },
      },
    },

    billingIsShipping: true,
    isShippingMethodSelectionInProgress: false,
    shippingMethods: null,
  };

  return rtn;
};

export const useShippingStore = defineStore('shippingStore', {
  state: (): State => {
    return getInitialState();
  },
  actions: {
    setCustomer(addressType: AddressTypeEnum, data) {
      this.address.customer[addressType] = data;
    },

    resetCustomer(addressType: AddressTypeEnum) {
      this.address.customer[addressType] = getInitialState().address.customer[addressType];
    },

    setAddress(addressType: AddressTypeEnum, address) {
      this.address[addressType].streetAddress = [...address.streetAddress];
      this.address[addressType].city = address.city;
      this.address[addressType].postalCode = address.postalCode;
      this.address[addressType].region = address.region;
      this.address[addressType].country = address.country;
    },

    resetAddress(addressType: AddressTypeEnum) {
      this.address[addressType] = getInitialState().address[addressType];
      this.address.saved[addressType] = false;
    },

    async saveAddress(
      addressType: AddressTypeEnum,
      { customer, address }: { customer?: ToDo; address: ToDo },
    ) {
      const mainStore = useMainStore();

      // Reset saved address
      this.address.saved[addressType] = false;
      this.address.editing[addressType] = false;

      // Reset Form Errors
      this.address.errors[addressType].fields = [];
      this.address.errors[addressType].message = null;

      if (customer) {
        const isCustomerValid = this.validateCustomer(addressType, customer);

        if (isCustomerValid) {
          this.setCustomer(addressType, customer);
        }
      }

      const isAddressValid = this.validateAddress(addressType, address);

      if (isAddressValid) {
        this.setAddress(addressType, address);

        // This address is only saved when it contains no field errors
        this.address.saved[addressType] = !this.address.errors[addressType].fields.length;

        if (this.address.saved['shipping'] && this.address.saved['billing']) {
          mainStore.startAppLoading();
          await this.fetchShippingMethods();
          mainStore.endAppLoading();
        }

        if (addressType === 'shipping') {
          if (this.billingIsShipping) {
            await this.saveAddress('billing', { customer, address });
          }
        }
      }

      try {
        if (!this.address.saved[addressType]) {
          const invalidFieldLabels = this.address.errors[addressType].fields.map(
            (key) => fields[key],
          );

          this.address.errors[addressType].message =
            `The following fields are missing or incorrect from your ${addressType} address: ${invalidFieldLabels.join(', ')}`;
        }
      } catch (err) {
        console.log('HERE IS A BOOM', err);
      }
    },

    validateCustomer(addressType: AddressTypeEnum, customer) {
      const requiredFields = {
        firstName: fields.firstName,
        lastName: fields.lastName,
        phone: fields.phone,
      };

      let invalidFormFields = [];
      let isValid = true;

      // Add email address to validation if form is shipping
      if (addressType === 'shipping') {
        requiredFields.email = 'Email Address';
      }

      for (const fieldKey of Object.keys(requiredFields)) {
        const fieldValue = customer[fieldKey];

        // Check if value is set
        if (!customer[fieldKey]) {
          invalidFormFields.push(fieldKey);
          isValid = false;
          continue;
        }

        // Phone number
        if (fieldKey === 'phone') {
          if (!isValidPhoneNumber(fieldValue)) {
            invalidFormFields.push(fieldKey);
            isValid = false;
          }
          continue;
        }

        // Email address
        if (fieldKey === 'email') {
          if (!isValidEmailAddress(fieldValue)) {
            invalidFormFields.push(fieldKey);
            isValid = false;
          }
          continue;
        }

        // Additional check on the trimmed string required to ensure user does not submit only empty spaces
        // ie Submitting " " instead of "" will bypass the native html validation and we'd get no data :(
        if (typeof fieldValue === 'string' && !fieldValue.trim().length) {
          invalidFormFields.push(fieldKey);
          isValid = false;
          continue;
        }
      }

      // Push form fields to errors
      this.address.errors[addressType].fields =
        this.address.errors[addressType].fields.concat(invalidFormFields);

      return isValid;
    },

    validateAddress(addressType: AddressTypeEnum, address) {
      const requiredFields = {
        streetAddress: fields.streetAddress,
        city: fields.city,
        country: fields.country,
        postalCode: fields.postalCode,
      };

      let invalidFormFields = [];
      let isValid = true;

      for (const fieldKey of Object.keys(requiredFields)) {
        const fieldValue = address[fieldKey];

        // Check if value is set
        if (!fieldValue) {
          invalidFormFields.push(fieldKey);
          isValid = false;
          continue;
        }

        // Handle Street a little differently
        if (fieldKey === 'streetAddress') {
          const streetAddressLinesOneHasContent = !!fieldValue[0]?.trim().length;

          if (!streetAddressLinesOneHasContent) {
            invalidFormFields.push(fieldKey);
            isValid = false;
          }
          continue;
        }

        // Postal Code
        if (fieldKey === 'postalCode') {
          if (!isValidPostcode(fieldValue, address['country'])) {
            invalidFormFields.push(fieldKey);
            isValid = false;
          }
          continue;
        }

        // Additional check on the trimmed string required to ensure user does not submit only empty spaces
        // ie Submitting " " instead of "" will bypass the native html validation and we'd get no data :(
        if (typeof fieldValue === 'string' && !fieldValue.trim().length) {
          invalidFormFields.push(fieldKey);
          isValid = false;
          continue;
        }
      }

      // Push form fields to errors
      this.address.errors[addressType].fields =
        this.address.errors[addressType].fields.concat(invalidFormFields);

      return isValid;
    },

    async fetchShippingMethods() {
      const cartStore = useCartStore();

      try {
        const shippingMethods = await cartStore.getShippingMethods();

        if (shippingMethods) {
          this.shippingMethods = shippingMethods;
        } else {
          // Reset shipping methods // TODO this should probably not be here, we always expect this in the response unless an error has occured - DS
          this.resetShippingMethods();
        }
      } catch (err) {
        throw new ShippingError('Error in getting shipping methods!', err);
      }
    },

    resetShippingMethods() {
      this.shippingMethods = null;
      this.address.saved.shipping = false;
      this.address.editing.shipping = true;
    },

    submitShippingStep(router) {
      const mainStore = useMainStore();
      const cartStore = useCartStore();
      const advertiserStore = useAdvertiserStore();

      if (this.address.saved.shipping && cartStore.cart.shipping?.selectedShippingMethod) {
        mainStore.redirectToView(`/${advertiserStore.paymentProvider}/checkout`, router.push);
      }
    },
  },
  getters: {
    /**
     * Returns the billing customer email address otherwise the shipping customer email address if available.
     */
    getAvailableEmailAddress(state): string | undefined {
      const billingCustomerEmail = state.address.customer.billing.email;
      const shippingCustomerEmail = state.address.customer.shipping.email;

      return billingCustomerEmail || shippingCustomerEmail;
    },
  },
});
