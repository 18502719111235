<template>
  <StripeElement
    ref="paymentElement"
    type="expressCheckout"
    :elements="elementsInstance"
    :options="paymentElementOptions"
    @ready="onPaymentElementReady"
    @click="onPaymentElementClick"
  />
</template>

<script lang="ts">
import { defineComponent, ref } from 'vue';
import { StripeElement } from 'vue-stripe-js';

import { useAdvertiserStore } from '@/stores/AdvertiserStore';
import { useMainStore } from '@/stores/MainStore';
import { StripePaymentError } from '@/types/errors.types';

export default defineComponent({
  name: 'StripeFieldsExpressCheckout',
  components: {
    StripeElement,
  },
  props: {
    stripeInstance: null,
    elementsInstance: null,
    onReady: { type: Function, required: true },
    onShippingAddressChanged: { type: Function, required: true },
    onShippingRateChanged: { type: Function, required: true },
    createConfirmationToken: { type: Function, required: true },
    onPaymentElementConfirmed: { type: Function, required: true },
    totalPaymentPrice: Number,
    currencyCode: String,
  },
  setup(props) {
    const mainStore = useMainStore();
    const advertiserStore = useAdvertiserStore();

    const paymentElement = ref();
    const paymentElementOptions = ref({
      // https://stripe.com/docs/stripe.js#element-options
    });

    const onPaymentElementReady = () => {
      const { stripeElement } = paymentElement.value;

      stripeElement.on('confirm', onCompletePayment);
      stripeElement.on('shippingaddresschange', props.onShippingAddressChanged);
      stripeElement.on('shippingratechange', props.onShippingRateChanged);

      props.onReady();
    };

    const onCompletePayment = async (element) => {
      console.log('onCompletePayment() called', element);

      try {
        const confirmationToken = await props.createConfirmationToken(
          props.stripeInstance,
          props.elementsInstance,
        );

        const { shipping } = confirmationToken;
        const { billing_details: billingDetails } = confirmationToken.payment_method_preview;

        await props.onPaymentElementConfirmed({
          shippingAddress: shipping,
          billingDetails,
          confirmationToken,
          amount: props.totalPaymentPrice! * 100, // In pence
          currencyCode: props.currencyCode,
        });
      } catch (err) {
        throw new StripePaymentError(
          'An unhandled error occurred when attempting to create the confirmation token!',
          err,
          advertiserStore.paymentProvider!,
        );
      }
    };

    const onPaymentElementClick = (e) => {
      const options = {
        shippingAddressRequired: true,
        allowedShippingCountries: ['GB'],
        emailRequired: true,
        phoneNumberRequired: true,
        shippingRates: [
          {
            id: 'unspecified',
            displayName: 'Getting shipping options...',
            amount: 0,
          },
        ],
      };

      e.resolve(options);
    };

    return {
      elementsInstance: props.elementsInstance,
      paymentElementOptions,
      paymentElement,
      mainStore,
      onPaymentElementReady,
      onPaymentElementClick,
      onCompletePayment,
    };
  },
});
</script>
