<template>
  <div>
    <StripeElements
      v-if="stripeLoaded && isPaymentElementReadyForRender"
      v-slot="{ elements, instance }"
      ref="elements"
      :stripe-key="stripeKey"
      :instance-options="instanceOptions"
      :elements-options="elementsOptions"
    >
      <!-- TODO allowed country codes -->
      <!-- Express Checkout -->
      <StripeFieldsExpressCheckout
        v-if="isShowExpressCheckout"
        :stripeInstance="instance"
        :elementsInstance="elements"
        :onReady="() => onPaymentComponentReady('ExpressCheckout')"
        :onShippingAddressChanged="onShippingAddressChanged"
        :onShippingRateChanged="onExpresssCheckoutShippingRateChange"
        :createConfirmationToken="createConfirmationToken"
        :onPaymentElementConfirmed="onPaymentElementConfirm"
        :totalPaymentPrice="totalPaymentPrice"
        :currencyCode="currencyCode"
      />

      <!-- Card Payment (and address fields) -->
      <StripeFieldsCardPayment
        v-if="isShowCardPayment"
        :stripeInstance="instance"
        :elementsInstance="elements"
        :onReady="() => onPaymentComponentReady('CardFields')"
        :onShippingAddressChanged="onShippingAddressChanged"
        :onShippingRateChanged="onCardFormShippingRateChange"
        :createConfirmationToken="createConfirmationToken"
        :onPaymentElementConfirmed="onPaymentElementConfirm"
        :allowedCountryCodes="allowedCountryCodes"
        :totalPaymentPrice="totalPaymentPrice"
        :currencyCode="currencyCode"
      />
    </StripeElements>
  </div>
</template>

<script lang="ts">
import { defineComponent, ref, computed, onBeforeMount, onMounted } from 'vue';
import {
  type BillingDetails,
  type ConfirmationToken,
  type ShippingAddress,
  type Stripe,
  type StripeElements,
} from '@stripe/stripe-js';
import { StripeElements as StripeElementsComponent, StripeElement } from 'vue-stripe-js';

import StripeFieldsExpressCheckout from '@/components/Payment/Stripe/StripeFieldsExpressCheckout.vue';
import StripeFieldsCardPayment from '@/components/Payment/Stripe/StripeFieldsCardPayment.vue';

import { useMainStore } from '@/stores/MainStore';
import { useCartStore } from '@/stores/CartStore';
import { useShippingStore } from '@/stores/ShippingStore.js';
import { useAdvertiserStore } from '@/stores/AdvertiserStore';
import { usePaymentStore } from '@/stores/PaymentStore';

import { getRouter } from '@/router';
import { StripePaymentError } from '@/types/errors.types';
import constants from '@/constants';

const mockServerUrl = import.meta.env.VITE_LOCAL_NODE_SERVER_URL;

type PaymentComponentType = 'ExpressCheckout' | 'CardFields';

export default defineComponent({
  name: 'StripeFields',
  components: {
    StripeElements: StripeElementsComponent,
    StripeElement,
    StripeFieldsExpressCheckout,
    StripeFieldsCardPayment,
  },
  props: {
    doLoadStripe: {
      type: Function,
      required: true,
      default: () => {},
    },
    isShowExpressCheckout: Boolean,
    isShowCardPayment: Boolean,
  },
  setup(props) {
    const stripeKey = ref(
      'pk_test_51Ow1qjKNXLIApz7RPihcwhI6fq0P5toL0nvK9NhVz6JhSDatSdELjys3Tj1nOXbqLZWw3o1j7sdWrajwn9bM2pfQ00srO1fK6w',
    );
    const instanceOptions = ref({
      // https://stripe.com/docs/js/initializing#init_stripe_js-options
    });
    const elementsOptions = ref<{ clientSecret?: string }>({
      // https://stripe.com/docs/js/elements_object/create#stripe_elements-options
    });

    const stripeLoaded = ref(false);
    const instance = ref<Stripe>();
    const elements = ref<StripeElements>();
    const paymentIntentId = ref<string>();
    const isPaymentElementReadyForRender = ref(false);
    const isComponentsAreReady = ref<Record<PaymentComponentType, boolean>>({
      ExpressCheckout: false,
      CardFields: false,
    });

    const mainStore = useMainStore();
    const cartStore = useCartStore();
    const advertiserStore = useAdvertiserStore();
    const shippingStore = useShippingStore();
    const paymentStore = usePaymentStore();

    const storeCurrency = mainStore.currency;
    const totalPaymentPrice = computed(() => cartStore.getTotalPaymentPrice);
    const allowedCountryCodes = constants.ALLOWED_SHIPPING_COUNTRY_CODES;

    /* Don't write tests for this as it'll be happening on the cart init call. */
    const createPaymentIntent = async () => {
      const paymentConfig = {
        amount: totalPaymentPrice.value * 100, // In pence
        currency: storeCurrency.currencyCode,
      };

      // TODO read up on whether we actually need to specify paymentMethodTypes.

      if (await isCardPaymentFlow()) {
        paymentConfig.paymentMethodTypes = ['card'];
      } else {
        // ///////////////////////////////////////////////////////////////////////////////////////////////////////////////
        // ///////////////////////////////////////////////////////////////////////////////////////////////////////////////
        // TODO once updated from main branch, augment this with the value of 'enabledPaymentMethods' in the Payment Store.
        // ///////////////////////////////////////////////////////////////////////////////////////////////////////////////
        // ///////////////////////////////////////////////////////////////////////////////////////////////////////////////

        paymentConfig.automatic_payment_methods = {
          enabled: true,
          allow_redirects: 'never',
        };
      }

      const result = await fetch(`https://${mockServerUrl}/create-payment-intent`, {
        mode: 'cors', // ////
        method: 'POST',
        cache: 'no-cache',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(paymentConfig),
      });

      const {
        clientSecret,
        paymentIntentId,
        error: backendError,
        publishableKey,
      } = await result.json();

      if (backendError) {
        // todo
      }

      return { clientSecret, paymentIntentId };
    };

    // THIS WILL BE HANDLE SERVER SIDE JUST LEAVING HERE FOR THIS POC
    const updatePaymentIntent = async () => {
      // TODO move the fetches to the api/ directory like I did with adyen
      const result = await fetch(`https://${mockServerUrl}/update-payment-intent`, {
        mode: 'cors', // ////
        method: 'POST',
        cache: 'no-cache',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          paymentIntentId: paymentIntentId.value,
          amount: totalPaymentPrice.value * 100, // In pence
          currency: storeCurrency.currencyCode,
        }),
      });

      const { clientSecret, error: backendError } = await result.json();

      if (backendError) {
        // todo
      }

      return clientSecret;
    };

    const initPaymentElement = async () => {
      const paymentIntentResponse = await createPaymentIntent();

      elementsOptions.value.clientSecret = paymentIntentResponse.clientSecret;
      paymentIntentId.value = paymentIntentResponse.paymentIntentId;

      isPaymentElementReadyForRender.value = true;
    };

    const isCardPaymentFlow = async () => {
      const router = await getRouter();
      return router.currentRoute.value.name === 'StripeCheckout';
    };

    const onPaymentComponentReady = async (componentType: PaymentComponentType) => {
      let setAsInitialised = false;

      isComponentsAreReady.value = {
        ...isComponentsAreReady.value,
        [componentType]: true,
      };

      if (await isCardPaymentFlow()) {
        // Just need the CardFields element to be ready
        setAsInitialised = isComponentsAreReady.value['CardFields'];
      } else {
        // Just need the ExpressCheckout element to be ready
        setAsInitialised = isComponentsAreReady.value['ExpressCheckout'];
      }

      if (setAsInitialised) {
        mainStore.setPaymentButtonInitialised(
          advertiserStore.paymentProvider,
          'stripeCheckout',
          'INITIALISED',
        );
      }
    };

    const onShippingAddressChanged = async ({ resolve, address }) => {
      console.log('onShippingAddressChanged() called', address);

      try {
        const newShippingOptions: {
          id: string;
          displayName: string;
          amount: number;
        }[] = [];

        const shippingAddress = {
          streetAddress: address.streetAddress || [],
          city: address.city,
          country: address.country,
          postalCode: address.postal_code,
          region: address.state,
        };

        shippingStore.setAddress('billing', shippingAddress);
        shippingStore.setAddress('shipping', shippingAddress);

        await shippingStore.fetchShippingMethods();

        (shippingStore.shippingMethods || []).forEach((method) => {
          newShippingOptions.push({
            id: method.code,
            displayName: `${method.label} (${method.amount} ${storeCurrency.currencyCode})`,
            amount: method.amount * 100,
          });
        });

        if (!newShippingOptions.length) {
          throw new Error('There were no shipping methods to display!');
        }

        // (Assume) that the UI will choose the first option and set that as selected in the store
        await cartStore.setShippingMethod(newShippingOptions[0].id);

        // THIS WILL BE HANDLE SERVER SIDE JUST LEAVING HERE FOR THIS POC
        await updatePaymentIntent();

        return resolve({
          shippingRates: newShippingOptions,
        });
      } catch (err) {
        throw new StripePaymentError(
          'Error in onShippingAddressChanged()!',
          err,
          advertiserStore.paymentProvider,
        );
      }
    };

    const onExpresssCheckoutShippingRateChange = async ({ resolve, shippingRate }) => {
      console.log('onExpresssCheckoutShippingRateChange() called', shippingRate);

      // TODO error handling

      await cartStore.setShippingMethod(shippingRate.id);

      // THIS WILL BE HANDLE SERVER SIDE JUST LEAVING HERE FOR THIS POC
      await updatePaymentIntent();

      await elements.value.elements.fetchUpdates();

      return resolve();
    };

    // TODO the duplication with onExpresssCheckoutShippingRateChange() is a little untidy - wait until later to fix
    const onCardFormShippingRateChange = async (shippingMethod: string) => {
      console.log('onCardFormShippingRateChange() called', shippingMethod);

      await cartStore.setShippingMethod(shippingMethod);

      // THIS WILL BE HANDLE SERVER SIDE JUST LEAVING HERE FOR THIS POC
      await updatePaymentIntent();

      console.log('XXX', elements);

      await elements.value.elements.fetchUpdates();
    };

    const createConfirmationToken = async (
      stripeInstance: Stripe,
      elementsInstance: StripeElements,
    ) => {
      const { error: createConfirmationTokenError, confirmationToken } =
        await stripeInstance.createConfirmationToken({
          elements: elementsInstance,
        });

      if (createConfirmationTokenError) {
        throw createConfirmationTokenError;
      }

      if (
        !confirmationToken.shipping ||
        !confirmationToken.payment_method_preview?.billing_details
      ) {
        throw new Error('Could not find customer details in confirmation token!');
      }

      return confirmationToken;
    };

    const onPaymentElementConfirm = async ({
      shippingAddress,
      billingDetails,
      confirmationToken,
      amount,
      currencyCode,
    }: {
      shippingAddress: ShippingAddress;
      billingDetails: BillingDetails;
      confirmationToken: ConfirmationToken;
      amount: number;
      currencyCode: string;
    }) => {
      console.log('onPaymentConfirm() called with', {
        shippingAddress,
        billingDetails,
        confirmationToken,
        amount,
        currencyCode,
      });

      const shippingAddressInfo = {
        streetAddress: [shippingAddress?.address.line1, shippingAddress?.address.line2],
        city: shippingAddress?.address.city,
        country: shippingAddress?.address.country,
        postalCode: shippingAddress?.address.postal_code,
        region: shippingAddress?.address.state,
      };

      const billingAddress = {
        streetAddress: [billingDetails?.address.line1, billingDetails?.address.line2],
        city: billingDetails?.address.city,
        country: billingDetails?.address.country,
        postalCode: billingDetails?.address.postal_code,
        region: billingDetails?.address.state,
      };

      const shippingCustomer = {
        firstName: shippingAddress?.name,
        lastName: shippingAddress?.name,
      };

      const billingCustomer = {
        firstName: billingDetails?.name,
        lastName: billingDetails?.name,
        email: billingDetails?.email,
      };

      console.log('Setting address and customer data:', {
        shippingAddressInfo,
        billingAddress,
        shippingCustomer,
        billingCustomer,
      });

      shippingStore.setAddress('shipping', shippingAddressInfo);
      shippingStore.setAddress('billing', billingAddress);

      shippingStore.setCustomer('shipping', shippingCustomer);
      shippingStore.setCustomer('billing', billingCustomer);

      paymentStore.setStripePaymentData({
        confirmationToken,
        amount,
        currencyCode,
      });

      await paymentStore.placeStripeOrder();

      // TODO error handling
      // TODO Make sure totals are updated when JWT is returned after setting shippinh (needs further back end integration)

      return Promise.resolve(true);
    };

    onBeforeMount(async () => {
      await props.doLoadStripe(stripeKey.value);
      stripeLoaded.value = true;
    });

    onMounted(() => {
      initPaymentElement();
    });

    return {
      stripeKey,
      stripeLoaded,
      isPaymentElementReadyForRender,
      instanceOptions,
      elementsOptions,
      elements,
      instance,
      onPaymentComponentReady,
      onShippingAddressChanged,
      onExpresssCheckoutShippingRateChange,
      onCardFormShippingRateChange,
      onPaymentElementConfirm,
      createConfirmationToken,
      allowedCountryCodes: [...allowedCountryCodes],
      totalPaymentPrice,
      currencyCode: storeCurrency.currencyCode,
    };
  },
});
</script>
