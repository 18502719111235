<template>
  <div ref="paymentContainerRef" />
</template>

<script lang="ts">
import { defineComponent, onMounted, ref, toRefs, computed } from 'vue';

import { useMainStore } from '@/stores/MainStore';
import { useCartStore } from '@/stores/CartStore';
import { useAdvertiserStore } from '@/stores/AdvertiserStore';
import { usePaymentStore } from '@/stores/PaymentStore';
import { useShippingStore } from '@/stores/ShippingStore';

import { Api as PaymentApi } from '@/api/payment/adyen';

import type { PropType } from 'vue';
import type { PaymentButtonTypeAdyen } from '@/stores/MainStore';
import { AdyenPaymentError } from '@/types/errors.types';

export default defineComponent({
  name: 'AdyenExpressPayPal',
  components: {},
  props: {
    // TODO use this TS PropType technique in other components!
    onReady: { type: Function as PropType<(p: PaymentButtonTypeAdyen) => void>, required: true },
    adyenSdk: null,
    paymentsClient: null,
    currency: { type: String, required: true },
    onShippingAddressChanged: null,
    onShippingOptionsChange: null,
  },
  setup(props) {
    const mainStore = useMainStore();
    const cartStore = useCartStore();
    const advertiserStore = useAdvertiserStore();
    const paymentStore = usePaymentStore();
    const shippingStore = useShippingStore();

    const paymentContainerRef = ref();
    const shopthruPspReference = ref(null);

    const totalPaymentPrice = computed(() => cartStore.getTotalPaymentPrice);

    const onPaymentElementReady = () => {
      props.onReady('paypal');
    };

    const onPaymentError = (error) => {
      const errorFn = () => {
        mainStore.goToErrorPage({
          error,
        });
      };

      errorFn();

      /* TODO
      if (this.googlePaymentInstance?.teardown) {
        return this.googlePaymentInstance.teardown(errorFn);
      } else {
        errorFn();
      }
        */
    }; // TODO consider applying this to other components

    const initPaymentElement = () => {
      const paypalElement = new props.adyenSdk!.PayPal(props.paymentsClient, {
        isExpress: true,
        blockPayPalCreditButton: true,
        blockPayPalPayLaterButton: true,
        onShippingAddressChange: async (data: ToDo, actions: ToDo, component: ToDo) => {
          console.log('onShippingAddressChange() called!', data, actions, component);

          try {
            await onShippingAddressChange(data, actions, component);
          } catch (err) {
            console.error('Error in onShippingAddressChange()', err);
            actions.reject();
            return onPaymentError(err);
          }
        },
        onShippingOptionsChange: async (data: ToDo, actions: ToDo, component: ToDo) => {
          console.log('onShippingOptionsChange() called!', data, actions, component);

          try {
            await onShippingOptionsChange(data, actions, component);
          } catch (err) {
            console.error('Error in onShippingOptionsChange()', err);
            actions.reject();
            return onPaymentError(err);
          }
        },
        onSubmit: async (state: ToDo, component: ToDo, actions: ToDo) => {
          console.log('onSubmit() called', state, component, actions);

          try {
            const submissionResponse = await onSubmit(state, component, actions);
            return actions.resolve(submissionResponse);
          } catch (err) {
            console.error('Error in onSubmit()', err);
            actions.reject();
            return onPaymentError(err);
          }
        },
        onAuthorized: async (data: ToDo, actions: ToDo) => {
          console.log('onAuthorized() called', data, actions);

          try {
            await onAuthorized(data, actions);
            return actions.resolve();
          } catch (err) {
            console.error('Error in onAuthorized()', err);
            actions.reject();
            return onPaymentError(err);
          }
        },
        onAdditionalDetails: async (state: ToDo, component: ToDo, actions: ToDo) => {
          console.log('onAdditionalDetails() called', state, component, actions);

          try {
            const responseData = await onAdditionalDetails(state, component, actions);
            return actions.resolve(responseData);
          } catch (err) {
            console.error('Error in onAdditionalDetails()', err);
            actions.reject();
            return onPaymentError(err);
          }
        },
      });

      paypalElement.mount(paymentContainerRef.value);

      onPaymentElementReady();
    };

    const onShippingAddressChange = (data: ToDo, actions: ToDo, component: ToDo) => {
      console.log('onShippingAddressChange() called!', data, actions, component);

      const { shippingAddress } = data;

      const address = {
        streetAddress: [''],
        city: shippingAddress.city,
        region: shippingAddress.state,
        postalCode: shippingAddress.postalCode,
        country: shippingAddress.countryCode,
      };

      const shippingOptionsTransformFn = (shippingOption: ToDo) => ({
        id: shippingOption.code,
        type: 'SHIPPING',
        label: shippingOption.label,
        amount: {
          value: shippingOption.amount * 100,
          currency_code: props.currency,
        },
        selected: false,
      });

      return props.onShippingAddressChanged({
        component,
        address,
        shippingOptionsTransformFn,
        shopthruPspReference: shopthruPspReference.value,
      });
    };

    const onShippingOptionsChange = (data: ToDo, actions: ToDo, component: ToDo) =>
      props.onShippingOptionsChange({
        component,
        id: data.selectedShippingOption.id,
        shopthruPspReference: shopthruPspReference.value,
      });

    const onSubmit = async (state: ToDo, component: ToDo, actions: ToDo) => {
      const { paymentMethod } = state.data;
      const amount = {
        value: totalPaymentPrice.value * 100,
        currency: props.currency,
      };

      const resultData = await PaymentApi.submitPayment({
        amount,
        paymentMethod,
      });

      shopthruPspReference.value = resultData.shopthruPspReference;

      return resultData;
    };

    const onAuthorized = (data: ToDo, actions: ToDo) => {
      const { authorizedEvent, billingAddress, deliveryAddress } = data;

      if (authorizedEvent.status !== 'APPROVED') {
        throw new Error('Unexpected authorisation status'); // TODO fine tune if needed
      }

      const shippingAddressInfo = {
        streetAddress: [deliveryAddress.street],
        city: deliveryAddress.city,
        country: deliveryAddress.country,
        postalCode: deliveryAddress.postalCode,
        region: deliveryAddress.stateOrProvince,
      };

      const billingAddressInfo = {
        streetAddress: [billingAddress.street],
        city: billingAddress.city,
        country: billingAddress.country,
        postalCode: billingAddress.postalCode,
        region: billingAddress.stateOrProvince,
      };

      const shippingCustomer = {
        firstName: deliveryAddress.firstName,
        lastName: deliveryAddress.firstName,
      };

      const billingCustomer = {
        firstName: authorizedEvent.payer?.name?.given_name,
        lastName: authorizedEvent.payer?.name?.surname,
        email: authorizedEvent.payer?.email_address,
        phone: authorizedEvent.payer?.phone?.phone_number?.national_number,
      };

      console.log('Working with address and customer data:', {
        shippingAddressInfo,
        billingAddressInfo,
        shippingCustomer,
        billingCustomer,
      });

      shippingStore.setAddress('shipping', shippingAddressInfo);
      shippingStore.setAddress('billing', billingAddressInfo);

      shippingStore.setCustomer('shipping', shippingCustomer);
      shippingStore.setCustomer('billing', billingCustomer);
    };

    const onAdditionalDetails = async (state: ToDo, component: ToDo, actions: ToDo) => {
      const { details, paymentData } = state.data;

      const resultData = await PaymentApi.submitPayPalPaymentDetails({
        details,
        paymentData,
      });

      if (resultData?.resultCode !== 'Authorised') {
        return mainStore.goToErrorPage({
          error: new AdyenPaymentError(
            `Unexpected result code ${resultData.resultCode} in onAdditionalDetails()!`,
            null,
            advertiserStore.paymentProvider!,
          ),
        });
      }

      paymentStore.setAdyenPaymentData({
        amount: {
          value: totalPaymentPrice.value * 100,
          currency: props.currency,
        },
        details, // TODO remove what is not required after BE implementation
        paymentData, // TODO remove what is not required after BE implementation
        shopthruPspReference: shopthruPspReference.value!,
      });

      await paymentStore.placeAdyenOrder();

      return resultData;
    };

    onMounted(() => {
      initPaymentElement();
    });

    return {
      paymentContainerRef,
    };
  },
});
</script>
