import { defineStore } from 'pinia';
import * as Sentry from '@sentry/vue';
import {
  getInitialisedCart,
  setCartDiscounted,
  setCartItems,
  getCartShippingMethods,
  setCartShipping,
  setCartOrdered,
  setCartOrderedTEMPAdyenExperiment,
} from '@/api/cart';
import { useMainStore } from '@/stores/MainStore';
import { useShippingStore } from '@/stores/ShippingStore';
import { usePaymentStore } from '@/stores/PaymentStore';
import { useProductStore } from '@/stores/ProductStore';
import { decodeJwt } from '@/helpers/jwtUtils';
import gtmTracker from '@/helpers/googleTagManager';
import { getOptionedProductHash } from '@/helpers/productVariants';
import { CustomerRecordError, CartInitError } from '@/types/errors.types';
import { getRouter } from '@/router';

import {
  CartInitial,
  CartPriced,
  CartShippingWaiting,
  CartShippingSet,
  CartComplete,
} from '@/types/cart.types';

import type { PaymentPopupDataType } from '@/stores/MainStore';
import type { Cart, CartJwtPayload } from '@/types/cart.types';
import type { ShippingMethod, AddressTypeEnum } from '@/stores/ShippingStore';
import type { State as PaymentStoreStateType } from '@/stores/PaymentStore';

import type {
  ProductPricingAndAvailablityConfigurable,
  ProductPricingAndAvailablitySimple,
  ProductPricingAndAvailablityVariant,
} from '@/types/product.types';

type State = {
  isCartInitialised: boolean;
  selectedProductOptions: Record<string, string>;
  selectedProductOptionsHash: string | null;
  combinationAvailability: Record<string, Record<string, { isAvailable: boolean }>>;

  cart: Cart;
  cartJwt: CartJwtPayload | null;

  isQuantityChangeInProgress: boolean;
  isDiscountRevoked: boolean;
};

const makeCartApiCall = ({
  apiCallFn,
  cartId,
  payload = {},
  cartJwt,
}: {
  apiCallFn: Function;
  cartId: string;
  payload?: {};
  cartJwt: CartJwtPayload;
}) => {
  if (!cartId) {
    throw new Error('Missing required Cart ID in API call!');
  }

  return apiCallFn({ cartId, payload, cartJwt });
};

// TODO remove export in time
export const parseCartJwt = <T>(jwt: CartJwtPayload): T => {
  return <T>decodeJwt<CartJwtPayload>(jwt);
};

const getCustomerFromAvailableShippingState = (
  customerState,
): {
  email: string | null;
  firstName: string | null;
  lastName: string | null;
  customerTakenFromAddressType: AddressTypeEnum | undefined;
} => {
  let customerTakenFromAddressTypes: AddressTypeEnum[] = ['billing', 'shipping'];
  let customerTakenFromAddressType;
  let email: string | null = null;
  let firstName: string | null = null;
  let lastName: string | null = null;

  const isValidCustomerRecord = () => {
    return email && firstName && lastName;
  };

  for (const addressType of customerTakenFromAddressTypes) {
    customerTakenFromAddressType = addressType;

    email = customerState[customerTakenFromAddressType].email;
    firstName = customerState[customerTakenFromAddressType].firstName;
    lastName = customerState[customerTakenFromAddressType].lastName;

    if (isValidCustomerRecord()) {
      break;
    }
  }

  if (!isValidCustomerRecord()) {
    throw new CustomerRecordError('Could not form expected Customer fields from state!');
  }

  return { email, firstName, lastName, customerTakenFromAddressType };
};

const getInitialState = (): State => ({
  isCartInitialised: false,
  selectedProductOptions: {},
  selectedProductOptionsHash: null,
  combinationAvailability: {},

  cart: null,
  cartJwt: null,

  isQuantityChangeInProgress: false,
  isDiscountRevoked: false,
});

export const useCartStore = defineStore('cartStore', {
  state: (): State => {
    return getInitialState();
  },
  actions: {
    // TODO Error handling
    async init(code: string) {
      const router = await getRouter();
      const mainStore = useMainStore();
      const paymentStore = usePaymentStore();
      const productStore = useProductStore();
      let cartResult;

      try {
        const queryParams = mainStore.parentLocation?.href
          ? {
              'parent-href': mainStore.parentLocation?.href,
            }
          : undefined;

        cartResult = await getInitialisedCart(code, queryParams);
      } catch (err) {
        if (err.response?.status === 404) {
          if (mainStore.getFallbackUrl) {
            console.info('Cart initialisation issue, redirecting to Product Not Found page...');

            mainStore.redirectToView('/product-not-found', router.replace);
          } else {
            console.info('Cart initialisation issue, redirecting to Error page...');

            mainStore.goToErrorPage({
              error: new CartInitError(
                'Cart initialisation erred and no fallback URL is available!',
              ),
            });
          }

          return;
        } else {
          throw err;
        }
      }

      this.cartJwt = cartResult.data.cartJwt;

      this.cart = new CartInitial(parseCartJwt(this.cartJwt!).cart);

      // TODO this is only for Braintree
      paymentStore.setTransactionToken(cartResult.data.paymentConfig.paymentProviderToken);
      paymentStore.setPaymentEnvironment(cartResult.data.paymentConfig.environment);

      paymentStore.setEnabledPaymentMethods(cartResult.data.paymentConfig.paymentMethods);

      // Initialise the option selection availability such that each potential option is set to available
      if (productStore.isProductHasVariants) {
        this.initCombinationAvailability();
      } else {
        await this.incrementQuantity();
      }

      this.isCartInitialised = true;
    },

    async initAsPaymentPopup({
      cartJwt,
      transaction,
      paymentEnvironment,
      paymentMethods,
    }: PaymentPopupDataType) {
      const paymentStore = usePaymentStore();

      // TODO transactionToken (maybe even a server call to validate it server side?)
      // TODO Cart validation (maybe even a server call to validate it server side?)
      this.cartJwt = cartJwt;

      this.cart = new CartPriced(parseCartJwt(this.cartJwt!).cart);

      // TODO this is only for Braintree
      paymentStore.setTransactionToken(transaction?.token!);

      paymentStore.setPaymentEnvironment(paymentEnvironment);

      paymentStore.setEnabledPaymentMethods(paymentMethods);

      this.isCartInitialised = true;
    },

    async setItems() {
      const productStore = useProductStore();
      const mainStore = useMainStore();

      try {
        const { id, productQuantity } =
          (this.cart as Pick<CartPriced, 'id' | 'productQuantity'>) || {};

        const payload: {
          productQuantity: number;
          variant?: {
            sku: string;
          };
        } = { productQuantity };

        if (productStore.isProductHasVariants) {
          const productVariant = productStore.getProductVariantFromOptions(
            this.selectedProductOptions,
          );
          payload.variant = { sku: productVariant.sku };
        }

        const result = await makeCartApiCall({
          apiCallFn: setCartItems,
          cartId: id,
          cartJwt: this.cartJwt!,
          payload,
        });

        this.cartJwt = result.data.cartJwt;
        this.cart = new CartPriced(parseCartJwt(this.cartJwt!).cart);
      } catch (err) {
        mainStore.goToErrorPage({ error: err });
      }
    },

    async setDiscounted() {
      const productStore = useProductStore();

      try {
        const { id } = (this.cart as Pick<CartPriced, 'id'>) || {};

        productStore.setDiscountLookupState('LOOKUP_IN_PROGRESS');

        const result = await makeCartApiCall({
          apiCallFn: setCartDiscounted,
          cartId: id,
          cartJwt: this.cartJwt!,
        });

        // You can test various states here e.g.
        // productStore.setDiscountLookupState('DISCOUNT_REVOKED');
        // or..
        // throw new Error('Simulating LOOKUP_ERRED');
        switch (result.status) {
          case 200:
            productStore.setDiscountLookupState('DISCOUNT_NOT_APPLICABLE');
            break;

          case 201:
            productStore.setDiscountLookupState('DISCOUNT_APPLIED');
            break;

          default:
            throw new Error('Discount lookup returned an unexpected status!');
        }

        this.cartJwt = result.data.cartJwt;
        this.cart = new CartPriced(parseCartJwt(this.cartJwt!).cart);
      } catch (err) {
        console.error('An error occurred in fetching discount', err);
        Sentry.captureException(err);
        productStore.setDiscountLookupState('LOOKUP_ERRED');
      }
    },

    async getShippingMethods(): Promise<ShippingMethod[]> {
      const { address } = useShippingStore();
      const cart = new CartShippingWaiting({
        ...(this.cart as CartPriced),
        addresses: {
          billingAddress: address.billing,
          shippingAddress: address.shipping,
        },
      });

      const { id, addresses } = cart;
      const payload = { addresses };

      const result = await makeCartApiCall({
        apiCallFn: getCartShippingMethods,
        cartId: id,
        cartJwt: this.cartJwt!,
        payload,
      });

      this.cartJwt = result.data.cartJwt;
      this.cart = new CartShippingWaiting(parseCartJwt(this.cartJwt!).cart);

      return result.data.shippingMethods;
    },

    async setShippingMethod(shippingMethod: string) {
      const cart = new CartShippingSet({
        ...(this.cart as CartShippingWaiting),
        shipping: {
          selectedShippingMethod: shippingMethod,
        },
      });

      const { id, shipping } = cart;
      const payload = { selectedShippingMethod: shipping.selectedShippingMethod };
      const result = await makeCartApiCall({
        apiCallFn: setCartShipping,
        cartId: id,
        cartJwt: this.cartJwt!,
        payload,
      });

      this.cartJwt = result.data.cartJwt;
      this.cart = new CartShippingSet(parseCartJwt(this.cartJwt!).cart);
    },

    async setOrdered(payment: { nonce: string; method: string }) {
      const { address } = useShippingStore();
      const { id } = (this.cart as Pick<CartShippingSet, 'id'>) || {};

      const payload = {
        payment,
        customer: getCustomerFromAvailableShippingState(address.customer),
        addresses: {
          billingAddress: address.billing,
          shippingAddress: address.shipping,
        },
      };

      const result = await makeCartApiCall({
        apiCallFn: setCartOrdered,
        cartId: id,
        cartJwt: this.cartJwt!,
        payload,
      });

      this.cartJwt = result.data.cartJwt;

      this.cart = new CartComplete(parseCartJwt(this.cartJwt!).cart);
    },

    async setOrderedAdyen(
      orderData:
        | PaymentStoreStateType['adyenPaymentDataPayPal']
        | PaymentStoreStateType['adyenPaymentDataPayGooglePay']
        | PaymentStoreStateType['adyenPaymentDataCard'],
    ) {
      const { address } = useShippingStore();
      const { id } = (this.cart as Pick<CartShippingSet, 'id'>) || {};

      const payload = {
        orderData,
        customer: getCustomerFromAvailableShippingState(address.customer),
        addresses: {
          billingAddress: address.billing,
          shippingAddress: address.shipping,
        },
      };

      const result = await makeCartApiCall({
        apiCallFn: setCartOrderedTEMPAdyenExperiment,
        cartId: id,
        cartJwt: this.cartJwt!,
        payload,
      });

      this.cartJwt = result.data.cartJwt;

      this.cart = new CartComplete(parseCartJwt(this.cartJwt!).cart);
    },

    async incrementQuantity(quantity = 1) {
      this.isQuantityChangeInProgress = true;
      this.cart!.productQuantity = (this.cart!.productQuantity || 0) + quantity;

      await this.setItems();

      gtmTracker.trackJourneyEvent({
        event: 'quantity_increment',
      });

      this.isQuantityChangeInProgress = false;
    },

    async decrementQuantity(quantity = 1) {
      const propsedNewQuantity = (this.cart!.productQuantity || 0) - quantity;
      this.isQuantityChangeInProgress = true;

      if (!propsedNewQuantity) {
        this.cart!.productQuantity = 1;
      } else {
        this.cart!.productQuantity = propsedNewQuantity;
      }

      await this.setItems();

      gtmTracker.trackJourneyEvent({
        event: 'quantity_decrement',
      });
      this.isQuantityChangeInProgress = false;
    },

    async setQuantity(quantity: number) {
      this.cart!.productQuantity = quantity || 1;
      return this.setItems();
    },

    async setProductOptions(options, doSubmit = true) {
      const productStore = useProductStore();

      this.isQuantityChangeInProgress = true;

      // Set the values to state
      options.forEach(({ variantCode, optionValue }) => {
        this.selectedProductOptions[variantCode] = optionValue;
      });

      // Set the current selection as the options hash
      this.selectedProductOptionsHash = getOptionedProductHash(
        Object.entries(this.selectedProductOptions).map(([variantCode, optionValue]) => ({
          variantCode,
          val: optionValue,
        })),
      );

      // Get the variant options per select group that are available given the current combination of variant selections
      const getAvailableOptions = (rootVariantCode: string) => {
        const variantsMatrixOptions = Object.values(productStore.variantsMatrix).map(
          (matrixVariant) => matrixVariant.variantOptions,
        );

        // Initialise empty Sets to keep track of available options
        const availableOptions = Object.keys(this.combinationAvailability).reduce(
          (acc, variantCode) => {
            acc[variantCode] = new Set();
            return acc;
          },
          {} as Record<string, Set<ToDo>>,
        );

        // For each possible variant combination, assert if it is available
        variantsMatrixOptions.forEach((variantGroup) => {
          let isAvailable = true;

          // Check availability for this variant combination, ignoring the current rootVariantCode being assessed
          for (const option in this.selectedProductOptions) {
            if (
              option !== rootVariantCode &&
              this.selectedProductOptions[option] !== '-1' &&
              !variantGroup.some(
                (variant: ToDo) =>
                  variant.variantCode === option &&
                  variant.variantId === this.selectedProductOptions[option],
              )
            ) {
              isAvailable = false;
              break;
            }
          }

          if (isAvailable) {
            variantGroup.forEach((variant: ToDo) => {
              availableOptions[variant.variantCode].add(variant.variantId);
            });
          }
        });

        return availableOptions;
      };

      // Update state to indicate what variant selections are available
      for (const variantCode in this.selectedProductOptions) {
        const availableOptions = getAvailableOptions(variantCode);

        for (const variantOption in this.combinationAvailability[variantCode]) {
          this.combinationAvailability[variantCode][variantOption].isAvailable =
            availableOptions[variantCode].has(variantOption) ||
            this.selectedProductOptions[variantCode] === variantOption;
        }
      }

      // If the complete selection is valid then update the cart
      if (this.getIsProductOptionsSelectionValid) {
        // A valid selection has been made
        if (doSubmit) {
          if (!this.cart!.productQuantity) {
            await this.incrementQuantity();
          } else {
            await this.setItems();
          }
        }
      }

      this.isQuantityChangeInProgress = false;
    },
    initCombinationAvailability() {
      const productStore = useProductStore();

      this.combinationAvailability = Object.keys(productStore.variantsMatrix).reduce(
        (acc, matrixVariantHash) => {
          const variants = matrixVariantHash.split('|');

          variants.forEach((variant) => {
            const [variantCode, optionValue] = variant.split('=');

            acc[variantCode] = {
              ...acc[variantCode],
              [optionValue]: { isAvailable: true },
            };
          });

          return acc;
        },
        {} as State['combinationAvailability'],
      );
    },
  },
  getters: {
    getFormattedPrice(): (price: number) => string {
      const { currency } = useMainStore();

      return (price) => {
        return `${currency.symbol}${parseFloat(price || 0).toFixed(2)}`;
      };
    },

    // Total price for goods with discounts applied
    getTotalCartPrice(state): number {
      const cart = state.cart as CartPriced;
      let rtn = 0;

      if (cart.pricing) {
        rtn = cart.pricing.totalDiscountedPrice || cart.pricing.totalUnitPrice;
      }

      return rtn;
    },

    // Total price for goods with discounts applied and shipping included
    getTotalPaymentPrice(state): number {
      const cart = state.cart as CartPriced;

      // Depending on the state of the Cart journey, take in order of (see API docs):
      return (
        cart.pricing?.grandTotal || // CartPricingInfoWithShipping
        cart.pricing?.totalDiscountedPrice || // CartPricingInfoDiscounts
        cart.pricing?.totalUnitPrice // CartPricingInfoBase
      );
    },

    getIsProductOptionsSelectionComplete(): boolean {
      const productStore = useProductStore();

      let isSelectionComplete = true;

      for (const uiOption of productStore.getProductUiOptions) {
        if (
          !this.selectedProductOptions[uiOption.optionCode] ||
          this.selectedProductOptions[uiOption.optionCode] === '-1'
        ) {
          isSelectionComplete = false;
          break;
        }
      }

      return isSelectionComplete;
    },

    /**
     * Is the total options selection valid, i.e. all options have been chosen but that option combination does not exist in the data.
     * Note, this should always be the case since the introduction of combinationAvailability but is left in as a safety net.
     */
    getIsProductOptionsSelectionValid(state): boolean {
      const productStore = useProductStore();

      return (
        !!productStore.variantsMatrix[this.selectedProductOptionsHash] &&
        state.getIsProductOptionsSelectionHasPricingData &&
        state.getIsProductOptionsSelectionInStock
      );
    },

    /**
     * Is the selected variant in stock.
     */
    getIsProductOptionsSelectionInStock(state): boolean {
      const productStore = useProductStore();
      const prAndAvail = productStore.getPricingAndAvailabilityByVariantSku(
        state.getSelectedProductVariant.sku,
      );

      return !!prAndAvail?.isInStock;
    },

    /**
     * Does the selected variant have associated Pricing and Availability data.
     */
    getIsProductOptionsSelectionHasPricingData(state): boolean {
      const productStore = useProductStore();
      const prAndAvail = productStore.getPricingAndAvailabilityByVariantSku(
        state.getSelectedProductVariant.sku,
      );

      return !!prAndAvail;
    },

    /**
     * As an option field is selected (e.g. colour) we can disable the other option fields (e.g. size) where that option is not available
     * for the colour choice.
     */
    getIsProductOptionSelectionAvailable:
      (
        state: State,
      ): ((
        variantCode: string,
        optionValue: string,
        previouslyMarkedAsAvailable?: boolean,
      ) => boolean) =>
      (variantCode, optionValue, previouslyMarkedAsAvailable) => {
        // '-1' indicates 'Please select a value' option
        if (optionValue === '-1') {
          return true;
        }

        return !!state.combinationAvailability[variantCode][optionValue].isAvailable;
      },

    /**
     * Get the variant as selected by the user from the option selection menus.
     */
    getSelectedProductVariant(): ProductPricingAndAvailablityVariant {
      const productStore = useProductStore();

      return productStore.getProductVariantFromOptions(this.selectedProductOptions);
    },

    /**
     * Just check the flag 'isInStock' on the ROOT of the product availablit.
     */
    getIsProductRootAvailable(): boolean {
      const productStore = useProductStore();
      const prAndAvail = productStore.productPricingAndAvailability;

      return !!prAndAvail?.isInStock;
    },

    /**
     * Get the Pricing and Availability information from either the root Simple type product
     * or the selected variant if the product is of type Configurable.
     */
    getSelectedProductPricingAndAvailability(
      state,
    ): ProductPricingAndAvailablitySimple | ProductPricingAndAvailablityConfigurable | undefined {
      const productStore = useProductStore();
      let rtn;

      if (productStore.isProductHasVariants) {
        // Configurable type product
        const selectedProductVariant = state.getSelectedProductVariant;
        const prAndAvail = productStore.getPricingAndAvailabilityByVariantSku(
          selectedProductVariant.sku,
        );

        rtn = prAndAvail;
      } else {
        // Simple type product
        const prAndAvail =
          productStore.productPricingAndAvailability as ProductPricingAndAvailablitySimple;

        rtn = prAndAvail;
      }

      return rtn;
    },

    /**
     * Get the selected product base price, taken from the selected variant option if root product
     * is of variant type.
     */
    getSelectedProductRegularPrice: (
      state: State & {
        getSelectedProductPricingAndAvailability: () =>
          | ProductPricingAndAvailablitySimple
          | ProductPricingAndAvailablityVariant;
      },
    ): (() => number | undefined) => {
      return () => {
        const prAndAvail = state.getSelectedProductPricingAndAvailability;
        return prAndAvail.priceRegular;
      };
    },
  },
});
