<template>
  <div class="ph-50" ref="paymentContainerRef" />
  <div v-if="submissionErrorsContainerRef" class="text-align-center colour-danger">
    <p>
      Payment failed due to: {{ submissionErrorsContainerRef }}
      <br />
      Please check your details and try again.
    </p>
  </div>
</template>

<script lang="ts">
import { defineComponent, onMounted, ref, PropType, computed } from 'vue';

import { useMainStore } from '@/stores/MainStore';
import { useAdvertiserStore } from '@/stores/AdvertiserStore.js';
import { usePaymentStore } from '@/stores/PaymentStore.js';
import { useCartStore } from '@/stores/CartStore.js';

import { Api as PaymentApi } from '@/api/payment/adyen';
import { AdyenPaymentError } from '@/types/errors.types';

import type {
  AdyenShippingMethodType,
  AdyenPaymentMethodsType,
  AdyenAvailablePaymentMethod,
} from '@/types/payment.adyen.types';

export default defineComponent({
  name: 'AdyenFieldsCardPayment',
  components: {},
  props: {
    onReady: { type: Function, required: true },
    adyenSdk: { type: Object as PropType<ToDo>, required: true },
    paymentMethodsResponseData: {
      type: Object as PropType<{ paymentMethods: AdyenAvailablePaymentMethod[] }>,
      required: true,
    },
    currency: { type: String, required: true },
    countryCode: { type: String, required: true },
    locale: { type: String, required: true },
  },
  setup(props) {
    const mainStore = useMainStore();
    const advertiserStore = useAdvertiserStore();
    const paymentStore = usePaymentStore();
    const cartStore = useCartStore();

    const paymentContainerRef = ref();
    const paymentsClient = ref();
    const submissionErrorsContainerRef = ref();

    const totalPaymentPrice = computed(() => cartStore.getTotalPaymentPrice);

    const initPaymentClient = async (adyenSdk = props.adyenSdk) => {
      const amount = {
        value: totalPaymentPrice.value * 100,
        currency: props.currency,
      };

      const configuration = {
        paymentMethodsResponse: props.paymentMethodsResponseData,
        clientKey: import.meta.env.VITE_ADYEN_CLIENT_KEY,
        environment: import.meta.env.VITE_APP_ENV === 'PROD' ? 'live' : 'test',
        amount,
        locale: props.locale,
        countryCode: props.countryCode,
      };

      paymentsClient.value = await adyenSdk!.AdyenCheckout(configuration);

      const card = new adyenSdk!.Card(paymentsClient.value, {
        onSubmit: async (state: ToDo, component: ToDo, actions: ToDo) => {
          console.log('onSubmit() called from component', state, component, actions);

          submissionErrorsContainerRef.value = undefined;

          try {
            const { isValid } = state;
            const { paymentMethod } = state.data;

            if (!isValid) {
              return actions.reject('Form was submitted when invalid!');
            }

            const resultData = await PaymentApi.submitPayment({
              amount,
              paymentMethod,
            });

            console.log('Submission result data:', resultData);

            if (resultData.error) {
              submissionErrorsContainerRef.value = resultData.error.reason;
              component.setStatus('error', { message: resultData.error.reason });
              return actions.reject();
            }

            if (resultData.resultCode !== 'Authorised') {
              mainStore.goToErrorPage({
                error: new AdyenPaymentError(
                  `Unexpected result code ${resultData.resultCode} in onSubmit()!`,
                  null,
                  advertiserStore.paymentProvider!,
                ),
              });
            }

            paymentStore.setAdyenPaymentData({
              amount,
              paymentMethod,
              shopthruPspReference: resultData.shopthruPspReference,
            });

            await paymentStore.placeAdyenOrder();

            return actions.resolve(resultData);
          } catch (error) {
            const message = 'Unexpected submission error!';

            console.error(message, error);

            actions.reject(error);

            /* TODO reenable
            mainStore.goToErrorPage({
              error: new AdyenPaymentError(
                message,
                error,
                advertiserStore.paymentProvider!,
              ),
            });
            */
          }
        },
        onError: async (state: ToDo, component: ToDo, actions: ToDo) => {
          console.log('onError() called', state, component, actions);
        },
      });

      console.log('CARD IS', card);

      card.mount(paymentContainerRef.value);
    };

    onMounted(() => {
      // TEMP, just for quik loading card screen in development
      function checkIsSdkLoaded() {
        if (window.Cypress) {
          initPaymentClient();
          clearInterval(sdkInterval);
          return;
        }

        if (window.AdyenWeb) {
          initPaymentClient(window.AdyenWeb);
          clearInterval(sdkInterval);
          return;
        }
      }

      const sdkInterval = setInterval(checkIsSdkLoaded, 250);
    });

    return {
      paymentContainerRef,
      submissionErrorsContainerRef,
    };
  },
});
</script>

<style lang="scss" scoped>
// @import './AdyenFieldsCardPayment.scss';
</style>
